import React from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = ({ labels, values, label, bgColor, borderColor, borderWidth,  pointRadius }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: values,
        fill: false,
        backgroundColor: bgColor,
        borderColor: borderColor,
        borderWidth: borderWidth,
        pointRadius, pointRadius
      }
    ]
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            position: 'left',
            reverse: true
          }
        }
      ]
    }
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
