import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { Card, CardBody, Col, Container, Row, Button} from 'reactstrap';
import jsPDF from 'jspdf'
// import S3 from 'aws-sdk'

//thumbnails
import Image1 from '../../assets/img/maps/lss/itogon.png'


//timestamp
import moment from 'moment-timezone';

import './DownloadableMap.css'
import lss1 from '../../assets/img/maps/lss/itogon.png'
import {Link } from 'react-router-dom'


const url= 'https://luzonlss.s3.ap-southeast-1.amazonaws.com/itogon.png';

const items = [
    {
        src: 'https://luzonlss.s3.ap-southeast-1.amazonaws.com/itogon.png',
        caption: 'Itogon Map',
        filename: 'ItogonLSS1',
        Image: '../../assets/img/maps/lss/itogon.png'
    },
    {
      src: 'https://luzonlss.s3.ap-southeast-1.amazonaws.com/itogon.png',
      caption: 'Itogon Map',
      filename: 'ItogonLSS2',
      Image: '../../assets/img/maps/lss/itogon.png'
    },
    {
      src: 'https://luzonlss.s3.ap-southeast-1.amazonaws.com/itogon.png',
      caption: 'Itogon Map',
      filename: 'ItogonLSS3',
      Image: '../../assets/img/maps/lss/itogon.png'
    }
]

const dateFormat = 'dddd, MMMM Do YYYY, h:mm:ss a z';



function DownloadableMap() {

	const [dateTime, setDateTime] = useState(moment().tz('Asia/Manila').format(dateFormat));
	const [pdf, setPDF] = useState('')

	setInterval(() => {
		setDateTime(moment().tz('Asia/Manila').format(dateFormat));
	}, 1000);

		const pdfGenerate = (e) => {
			e.preventDefault();
				var ts = dateTime
				var fn = items.filename
				var fn2 = fn?.toString()  || ''
				var doc = new jsPDF('portrait', 'px', 'a4');
				doc.text(10, 10, ts);;
				doc.addImage( Image1, 'PNG', 20, 40, 400,500)
				doc.save([items.filename])
      }


  return (
    <Fragment>
		 
          <section className='justify-content-center'>
			  <Container>
				<Row>
					  <Col>
					  <h1>Rainfall-Induced Landslide Susceptibility Map</h1>
						<hr className='hr1'/>
					  </Col>
				</Row>
				<Row>
        {items.map((item, i) => {
		
        //   console.log(item)
                return(
					<Col>
						<Card>
							<CardBody>
                
							<div className="container" key={'cont'+i} >
								{/* {pngs} */}
                  				<img src={item.src} key={'src' + i} alt='Image1'  className='image img-fluid'/>
							<div className="middle" key={'mid' + i}>
							<Button onClick={pdfGenerate} >
							{/* <Button key={i} > */}
							<div className="text" key={'text' + i}>Click to Download PDF</div>
							</Button>
							</div>
							</div>
							<div className='text-center' key={'textcen' + i} >
								<h3 className='text-dark' key={'textdark' + i}>Itogon Landslide Map</h3>
								<div>
									Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
								</div>
							</div>
							</CardBody>
						</Card>
					</Col>
          ) })}
					{/* <Col>
						<Card>
							<CardBody>
							<div class="container">
								<img src={require('Image1')} alt='Image1'  className='image img-fluid'/>
							<div class="middle">
								<div class="text">Sign In</div>
							</div>
							</div>
							<div className='text-center' >
								<h3 className='text-dark'>Itogon Landslide Map</h3>
								<div>
									Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
								</div>
							</div>
							</CardBody>
						</Card>
					</Col>
					<Col>
						<Card>
							<CardBody>
							<div class="container">
								<img src={Image1} alt='Image1'  className='image img-fluid'/>
							<div class="middle">
								<div class="text">Sign In</div>
							</div>
							</div>
							<div className='text-center' >
								<h3 className='text-dark'>Itogon Landslide Map</h3>
								<div>
									Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
								</div>
							</div>
							</CardBody>
						</Card>
					</Col> */}
				</Row>
			  </Container>
    </section>
    </Fragment>
  )
}

export default DownloadableMap