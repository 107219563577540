import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import RainTab from './RainTab';
import TemperatureTab from './TemperatureTab';
import HumidityTab from './HumidityTab';
import PressureTab from './PressureTab';
import Soil1Tab from './Soil1';

const bottomDesc = (
  <div style={{'fontSize': '10px'}}>
    E.M.Mangundu, J.N.Mateus, G.-A. Lusilao Zodi, J.Johnson, A wireless sensor network for rainfall
    monitoring, using cellular network: A case for namibia , 2017 Global Wireless Summit (GWS), Cape Town,
    South Africa, IEEE
  </div>
);

function HomeTabs() {
  const [activeTab, setActiveTab] = useState('rainfall');
  const [activeLoc, setActiveLoc] = useState('Sensor 1');


  return (
    <div>
      <div>
      <h1>{activeLoc} Graphs</h1>
        <select
          onChange={(e) => setActiveLoc(e.target.value)}
          defaultValue={activeLoc}
        >
          <option active={activeLoc === 'Sensor 1'} onClick={() => setActiveLoc('Sensor 1')} >Sensor 1</option>
          <option active={activeLoc === 'Sensor 2'} onClick={() => setActiveLoc('Sensor 2')} >Sensor 2</option>
          <option active={activeLoc === 'Sensor 3'} onClick={() => setActiveLoc('Sensor 3')}>Sensor 3</option>
        </select>
      </div>

      <Nav fill justified tabs>
      <NavItem>
          <NavLink active={activeTab === 'Soil Moisture'} onClick={() => setActiveTab('Soil Moisture')}>
            Soil Moisture
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === 'rainfall'} onClick={() => setActiveTab('rainfall')}>
            Rainfall
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === 'temperature'} onClick={() => setActiveTab('temperature')}>
            Temperature
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === 'windspeed'} onClick={() => setActiveTab('windspeed')}>
          Windspeed
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === 'humidity'} onClick={() => setActiveTab('humidity')}>
            Humidity
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='Soil Moisture'>
            <Soil1Tab />
            {bottomDesc}
          </TabPane>
        <TabPane tabId='rainfall'>
          <RainTab />
          {bottomDesc}
        </TabPane>
        <TabPane tabId='temperature'>
          <TemperatureTab />
          {bottomDesc}
        </TabPane>
        <TabPane tabId='windspeed'>
          <PressureTab />
          {bottomDesc}
        </TabPane>
        <TabPane tabId='humidity'>
          <HumidityTab />
          {bottomDesc}
        </TabPane>
      </TabContent>
    </div>
  );
}

export default HomeTabs;
