import React, {useState, useEffect} from 'react';

// import {useQuery} from "@apollo/client";
// Styles
import './Sensor.css';
import ImageSensor from '../../variables/ImageSensor'
import { MD } from '../GraphQLQueries/GetQuery'


// Leaflet
import L from 'leaflet';
import { FeatureGroup, Marker, Popup } from 'react-leaflet';
import icon from '../../assets/img/marker/antenna.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

//aws AppSync
import gql from 'graphql-tag';
import { compose, graphql} from 'react-apollo';
import { Card, CardBody } from 'reactstrap';

let DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow,
	iconSize: [20, 20],
	shadowSize: [30, 50],
	iconAnchor: [15, 30],
	shadowAnchor: [0, 50],
	popupAnchor: [0, 100]
});
// L.Marker.prototype.options.icon = DefaultIcon;

const MD1 = gql`
query RainRate {
	queryMountainDews2SBySitebyDate(Site: "MD1", first: 1) {
			items {
				BatteryLevel
				Date
				Humidity
				KeyId
				Pressure
				RainRate
				Site
				WindSpeed
				Temperature
				SoilMoisture1
				SoilMoisture2
				SoilMoisture3
				SoilEC1
				SoilEC2
				SoilEC3
				SoilTemperature1
				SoilTemperature2
				SoilTemperature3
			}
		}
	}
`;

const MD2 = gql`
	query RainRate {
		queryMountainDews2SBySitebyDate(Site: "MD2", first: 1) {
			items {
				BatteryLevel
				Date
				Humidity
				KeyId
				Pressure
				RainRate
				Site
				WindSpeed
				Temperature
				SoilMoisture1
				SoilMoisture2
				SoilMoisture3
				SoilEC1
				SoilEC2
				SoilEC3
				SoilTemperature1
				SoilTemperature2
				SoilTemperature3
			}
		}
	}
`;

const MD3 = gql`
	query RainRate {
		queryMountainDews2SBySitebyDate(Site: "MD3", first: 1) {
			items {
				BatteryLevel
				Date
				Humidity
				KeyId
				Pressure
				RainRate
				Site
				WindSpeed
				Temperature
				SoilMoisture1
				SoilMoisture2
				SoilMoisture3
				SoilEC1
				SoilEC2
				SoilEC3
				SoilTemperature1
				SoilTemperature2
				SoilTemperature3
			}
		}
	}
`;

const MD4 = gql`
query RainRate {
	queryMountainDews2SBySitebyDate(Site: "MD4", first: 1) {
			items {
				BatteryLevel
				Date
				Humidity
				KeyId
				Pressure
				RainRate
				Site
				WindSpeed
				Temperature
				SoilMoisture1
				SoilMoisture2
				SoilMoisture3
				SoilEC1
				SoilEC2
				SoilEC3
				SoilTemperature1
				SoilTemperature2
				SoilTemperature3
			}
		}
	}
`;

const img = [
	{
		src :'https://imgsensor.s3.ap-northeast-1.amazonaws.com/latestimagebase1',
		Site : 'MD1'
	}
]

const items = [
    {
        src: 'https://imgsensor.s3.ap-northeast-1.amazonaws.com/latestimagebase1',
        caption: 'Itogon Map',
        filename: 'ItogonLSS1',
        Image: '../../assets/img/maps/lss/itogon.png',
		Site: 'MD1'
    },
    {
        src: 'https://imgsensor.s3.ap-northeast-1.amazonaws.com/latestimagebase2',
        caption: 'Itogon Map',
        filename: 'ItogonLSS1',
        Image: '../../assets/img/maps/lss/itogon.png',
		Site: 'MD1'
    },
    {
        src: 'https://imgsensor.s3.ap-northeast-1.amazonaws.com/latestimagebase3',
        caption: 'Itogon Map',
        filename: 'ItogonLSS1',
        Image: '../../assets/img/maps/lss/itogon.png',
		Site: 'MD1'
    },
	{
        src: 'https://imgsensor.s3.ap-northeast-1.amazonaws.com/latestimagebase4',
        caption: 'Itogon Map',
        filename: 'ItogonLSS1',
        Image: '../../assets/img/maps/lss/itogon.png',
		Site: 'MD1'
    }
]


	





const MapPopup = (props) => {

	const [ selectOption, setSelectOption] = useState('');


	
	// const imgs = (
	// 	img
	// 	// .map(x => console.log(x))
	// 	  .map(x => Object.assign(x, props.site1.find(y => y.Site == x.Site)))
	// 	  .map((info, id) => {
	// 			return (
	// 				// console.log(info)
					
	// 			)
	// 			}
	// 	  )
	//   )

	

	return (

	<Marker position={props.point} icon={DefaultIcon}>
		{props.site1.map((site, id) => {
			console.log(site)
			return(

				<Popup className='sensor' >
				<div className='value name' >
					<i className='fas fa-map-marker-alt mr-2' />
					Location: {props.Name}
				</div>
				<div className='value site' >
					<i className='fas fa-map-marker-alt mr-2' />
					Site: {props.Site}
				</div>
				<div className='value temperature' >
					<i className='fa fa-thermometer-half mr-2' />
					Temperature:{site.Temperature} &#8451;
				</div>
				<div className='value humidity' >
					<i className='fa fa-tint mr-2' />
					Humidity:{site.Humidity}&#37;
				</div>
				<div className='value rain' >
					<i className='fa fa-cloud-sun-rain mr-2' />
					RainRate{site.RainRate}mm/hr
				</div>
				<div className='value wind'>
					<i className='fa fa-wind mr-2' />
					WindSpeed: {site.WindSpeed}
				</div>
				<div className='value soil1' >
					<span className='ml-2'>
						<i className='fa fa-water ml-2' />  &nbsp;
						SoilMoist1(15cm): {site.SoilMoisture1}
					</span>	
				</div>
				<div className='value soil2' >
					<span className='ml-2'>
						<a><i className='fa fa-water ml-2' /> &nbsp; </a>
						SoilMoist2(30cm):{site.SoilMoisture2}
					</span>	
				</div>
				<div className='value soil3' >
					<span className='ml-2'>
						<i className='fa fa-water ml-2' /> &nbsp;
						SoilMoist3(45cm): {site.SoilMoisture3}
					</span>	
				</div>
				<div className='value battery' >
					<i className='fa fa-battery-quarter mr-2' />
					Battery: {site.BatteryLevel}V
				</div>
				<div className='value Date' >
					<i className='fa fa-calendar smaller mr-2' />
					{site.Date}
				</div>
				<div className='value soilTemp1' >
					<i className='fa  fa-fire smaller mr-2' />
					Soil Temperature1: {site.SoilTemperature1}
				</div>
				<div className='value soilTemp2' >
					<i className='fa  fa-fire smaller mr-2' />
					Soil Temperature2: {site.SoilTemperature2}
				</div>
				<div className='value soilTemp3'>
					<i className='fa  fa-fire smaller mr-2' />
					Soil Temperature3: {site.SoilTemperature3}
				</div>
				<div className='value EC1' >
					<i className='fa  fa-magic smaller mr-2' />
					Soil EC1: {site.SoilEC1}
				</div>
				<div className='value EC2' >
					<i className='fa  fa-magic smaller mr-2' />
					Soil EC2: {site.SoilEC2}
				</div>
				<div className='value EC3' >
					<i className='fa  fa-magic smaller mr-2' />
					Soil EC3: {site.SoilEC3}
				</div>
				<div >
				<img 
				src = {props.Image}
				alt= {props.Name}
				className='value Image img-fluid'>
				</img> 
				</div>

			</Popup>
			);
		})}
	</Marker>

)
};

// export default MapPopup;

export default compose(
	graphql(MD1, {
		options: {
			fetchPolicy: 'cache-and-network'
		},
		props: (props) => ({
			site1: props.data.queryMountainDews2SBySitebyDate
				? props.data.queryMountainDews2SBySitebyDate.items
				: []
		})
	}),
	graphql(MD2, {
		options: {
			fetchPolicy: 'cache-and-network'
		},
		props: (props) => ({
			site2: props.data.queryMountainDews2SBySitebyDate
				? props.data.queryMountainDews2SBySitebyDate.items
				: []
		})
	}),
	graphql(MD3, {
		options: {
			fetchPolicy: 'cache-and-network'
		},
		props: (props) => ({
			site3: props.data.queryMountainDews2SBySitebyDate
				? props.data.queryMountainDews2SBySitebyDate.items
				: []
		})
	}),
	graphql(MD4, {
		options: {
			fetchPolicy: 'cache-and-network'
		},
		props: (props) => ({
			site4: props.data.queryMountainDews2SBySitebyDate
				? props.data.queryMountainDews2SBySitebyDate.items
				: []
		})
	})
	
)(MapPopup);
