import { useState } from 'react';
import { useMap } from 'react-leaflet';

function GetCoord({ setLocation }) {
  const [listener, setListener] = useState(true);
  const map = useMap();

  if (listener) {
    map.on('moveend', function () {
      setLocation(map.getCenter());
    });
    setListener(false);
  }

  return null;
}

export default GetCoord;
