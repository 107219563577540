import React, { useState, useEffect, useCallback } from 'react';
import { GeoJSON, LayersControl, FeatureGroup, Popup, ImageOverlay } from 'react-leaflet';

import axios from 'axios';
import routes_public from '../views/Guest/routes_public'


const LSjson = (props) => {
	const [LS, setLS] = useState([]);

	const GIFBounds =([16.318056, 120.584722],[16.472222, 120.716667])

	const styleGeoJSON = useCallback((feature) => {
		const {
		  properties: { Class }
		} = feature;
		return {
		  fillColor: LSColor(Class),
		  weight: 0.3,
		  opacity: 1.5,

		  dashArray: "3",
		  fillOpacity: 0.5
		};
	  }, []);

	useEffect(() => {
		Object.entries(routes_public).forEach(([d, k], i) => {
				axios.get(k.url).then((response)=> {
				console.log(response)
					let layer =
						<LayersControl.Overlay key={'title'+ d} name={k.title}>
							<FeatureGroup>
							<Popup key={'title'+d}>{k.title}</Popup>
								{/* <GeoJSON key={'ls'+d} data={response.data.features}  style={styleGeoJSON} /> */}
								<ImageOverlay 
								url={k.url}
								bounds={GIFBounds}
								 opacity={0.5}
								 zIndex={10}
								 />
							</FeatureGroup>
							
						</LayersControl.Overlay>	
					setLS(prevLayer => [...prevLayer,layer]);
				})
				
		});
	}, [styleGeoJSON]);

	console.log(LS)

	const LSColor = district => {
	switch (district) {
		case "Low":
			return "yellow";
		case "Moderate":
			return "green";
		case "High":
			return "red";
		case "Very High":
			return "brown";
		  default:
			return "blue";
		}
	  };


	return (
		<div>
			{LS}
		</div>
		
	
	
	)
			

};

export default LSjson
