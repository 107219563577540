import React from 'react';

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';

//aws AppSync
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';

const sensor = gql`
	query MyQuery {
		queryMountainDews2SBySitebyDate(Site: "1R", first: 1) {
			items {
				BatteryLevel
				Date
				Humidity
				Integrity
				KeyId
				Pressure
				RainRate
				Site
				Temperature
				WindSpeed
			}
		}
	}
`;

const HeaderChart = (props) => {
	var obj = props.todos;

	// var date = Object.entries(obj).map(([k, v], i) => {
	// 	return [obj[k].Date];
	// });
	var battery = Object.entries(obj).map(([k, v], i) => {
		return [obj[k].BatteryLevel];
	});

	var Humidity = Object.entries(obj).map(([k, v], i) => {
		return obj[k].Humidity;
	});

	var pressure = Object.entries(obj).map(([k, v], i) => {
		return obj[k].Pressure;
	});

	var temperature = Object.entries(obj).map(([k, v], i) => {
		return obj[k].Temperature;
	});

	return (
		<Container fluid>
			<div className='header-body'>
				{/* Card stats */}
				<Row>
					<Col lg='6' xl='3'>
						<Card className='card-stats mb-4 mb-xl-0'>
							<CardBody>
								<Row>
									<div className='col'>
										<CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
											Humidity
										</CardTitle>
										<span className='h2 font-weight-bold mb-0'>{Humidity} %</span>
									</div>
									<Col className='col-auto'>
										<div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
											<i className='fas fa-tint' />
										</div>
									</Col>
								</Row>
								{/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{' '}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
							</CardBody>
						</Card>
					</Col>
					<Col lg='6' xl='3'>
						<Card className='card-stats mb-4 mb-xl-0'>
							<CardBody>
								<Row>
									<div className='col'>
										<CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
											Pressure
										</CardTitle>
										<span className='h2 font-weight-bold mb-0'>{pressure} Pa</span>
									</div>
									<Col className='col-auto'>
										<div className='icon icon-shape bg-warning text-white rounded-circle shadow'>
											<i className='fas fa-cloud-sun' />
										</div>
									</Col>
								</Row>
								{/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{' '}
                        <span className="text-nowrap">Since last week</span>
                      </p> */}
							</CardBody>
						</Card>
					</Col>
					<Col lg='6' xl='3'>
						<Card className='card-stats mb-4 mb-xl-0'>
							<CardBody>
								<Row>
									<div className='col'>
										<CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
											Battery Level
										</CardTitle>
										<span className='h2 font-weight-bold mb-0'>{battery} V</span>
									</div>
									<Col className='col-auto'>
										<div className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
											<i className='fas fa-battery-full' />
										</div>
									</Col>
								</Row>
								{/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{' '}
                        <span className="text-nowrap">Since yesterday</span>
                      </p> */}
							</CardBody>
						</Card>
					</Col>
					<Col lg='6' xl='3'>
						<Card className='card-stats mb-4 mb-xl-0'>
							<CardBody>
								<Row>
									<div className='col'>
										<CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
											Temperature
										</CardTitle>
										<span className='h2 font-weight-bold mb-0'>{temperature} °C</span>
									</div>
									<Col className='col-auto'>
										<div className='icon icon-shape bg-info text-white rounded-circle shadow'>
											<i className='fas fa-thermometer-half' />
										</div>
									</Col>
								</Row>
								{/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{' '}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</Container>
	);
};

export default compose(
	graphql(sensor, {
		options: {
			fetchPolicy: 'cache-and-network'
		},
		props: (props) => ({
			todos: props.data.queryMountainDews2SBySitebyDate
				? props.data.queryMountainDews2SBySitebyDate.items
				: []
		})
	})
)(HeaderChart);
