import React, { Fragment } from 'react';

// reactstrap components
import { Col, Container, Row } from 'reactstrap';

import project from '../../assets/img/content/portfolio/project1.jpg';

const Portfolio = (props) => (
	<Fragment>
		<section className='justify-content-center bg-gradient-info'>
			<Row>
				<Col className='align-items-center text-center'>
					<Container>
						<h1 className='page-header w-100 p-3'>PORTFOLIO</h1>
						<div class='svg-border-rounded text-white'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 144.54 17.34'
								preserveAspectRatio='none'
								fill='currentColor'>
								<path d='M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0'></path>
							</svg>
						</div>
					</Container>
				</Col>
			</Row>
		</section>
		<section className='justify-content-center bg-white py-10vh'>
			<Container>
				<Row>
					<Col md='auto' className='text-right' data-aos='fade-right'>
						<img
							src={project}
							className='img-fluid'
							style={{ height: '50vh' }}
							alt='Projects'
						/>
					</Col>
					<Col
						className='d-flex flex-wrap align-items-center'
						data-aos='fade-left'>
						<div>
							<h1>
								Pilot Installation of MtDEWS Sensor in Itogon Benguet
								<p>December 17, 2020</p>
							</h1>
							<p>
								The SCaRP Project installed its prototype sensor in Itogon on
								December 17, 2020 as part of its pilot early warning system to
								prepare the study area’s Local Government Unit (LGU) against
								potential future landslides brought about by rainfall.
								<br />
								<br />
								The project from Mapua University, Manila is led by Project
								Leader Engr. Fibor J. Tan who visited the study area along with
								his team: Mr. Mark Anthony M. Matera and Ms. Sarah Alma P.
								Bentir, University Researchers II; Engr. Paolo A. Antazo and
								Engr. Jeremy C. De Leon, University Research Associates II; and
								Mr. Alec Louie Marohom, Project Staff II. The team conducted
								various fieldwork activities on December 15th – 18th ranging
								from but not limited to project presentation with LGU, landslide
								sites reconnaissance, sensor site assessment, sensor
								installation, and soil infiltration assessment.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	</Fragment>
);

export default Portfolio;
