import React, { Fragment, useCallback, useState } from 'react';
import { Col, Row,Button } from 'reactstrap';

import Map from 'components/Map/Map';
import AlertPop from 'components/AlertPop/AlertPop';
import Openweather from 'components/OpenweatherAPI/Openweather';
import Graph from 'components/Graph/Graph';

// Leaflet
import 'leaflet/dist/leaflet.css';
import './map.module.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import classes from './Home.module.css';

const Home = () => {
  const [mapLatLng, setMapLatLng] = useState({ lat: '12.2324', lng: '121.8565' })
  const [notif, setNotif] = useState({
    isVisible: false,
    type: 'success',
    message: ''
  });

  const handleNotif = useCallback((message, type = 'success') => {
    if (message) {
      setNotif({
        isVisible: true,
        type,
        message
      });
    }
  }, []);

  const handleCloseToggle = () => {
    setNotif({
      isVisible: false,
      type: 'success',
      message: ''
    });
  };


  return (
    <Fragment>
      <section>
        <Row className='m-0'>
          <Col
            lg='5'
            className={`order-last order-lg-first ${classes.wrapper}`}
            style={{ height: 'calc(90vh - 117px)' }}>
            <Openweather location={mapLatLng} />
            <hr className='my-3' />
            <Row>
              <Col>
                <Button block className='text-wrap'>
                  Landslide Susceptibility
                </Button>
              </Col>
              <Col>
                <Button block className='text-wrap'>
                  Quantification of Sediment
                </Button>
              </Col>
            </Row>
            <hr className='my-3' />
            <Graph />
          </Col>
          <Col lg='7' className='p-0 order-first order-lg-last'>
            <Map notif={handleNotif} setLocation={setMapLatLng} />
          </Col>
        </Row>
      </section>
      <AlertPop
        visible={notif.isVisible}
        color={notif.type}
        text={notif.message}
        toggle={handleCloseToggle}
      />
    </Fragment>
  );
};

export default Home;
