import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';

import LineChart from './LineChart';

const sensor = gql`
  query MyQuery {
    queryMountainDews2SBySitebyDate(Site: "MD1", first: 192) {
      items {
        RainRate
        Date
      }
    }
  }
`;

const RainTab = ({ todos: obj }) => {
  const values = Object.keys(obj).reduce(
    (agg, curr) => {
      let newAgg = agg;
      newAgg.date.push(obj[curr].Date);
      newAgg.sensor.push(obj[curr].RainRate);


      return newAgg;
    },
    { date: [], sensor: [] }
  );




  return (
    <LineChart
      labels={values.date}
      values={values.sensor}
      label='Rainfall rate'
      bgColor='rgba(15, 255, 51, 0.9)'
      borderColor='rgba(0, 28, 4, 1)'
      borderWidth='1'
      pointRadius='1'
    />
  );
};

export default compose(
  graphql(sensor, {
    options: {
      fetchPolicy: 'cache-and-network'
    },
    props: (props) => ({
      todos: props.data.queryMountainDews2SBySitebyDate
        ? props.data.queryMountainDews2SBySitebyDate.items
        : []
    })
  })
)(RainTab);
