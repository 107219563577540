import React, {useState, useMemo, Fragment} from 'react'
import {Rectangle, useMap} from 'react-leaflet'

// Leaflet
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import icon from '../../assets/img/marker/antenna.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';



function ItogonBounds() {


    const map = useMap();

    const redColor = { color: 'red' }
    const whiteColor = { color: 'white' }

    const style ={color:'transparent'}

    const Itogonbound = [
      [16.232311, 120.592890],
      [16.475534, 120.746840]
    ]

    const PhilBounds = [
      [5.465192, 116.944479],
      [18.830924, 126.768633]
    ]

    const LuzonBounds = [
        [18.48, 122.84],
        [14.18,119.55]
    ]


    const VisayasBounds = [
      [9.731919, 121.108217],
      [13.324049, 124.122398]
    ]

    const MindaBounds = [
        [6.411034, 123.734324],
        [9.233019, 126.517531]
    ]
    

    const Luzon= useMemo(
      () => ({
      click() {
        const LuzonBounds = [
          [18.48, 122.84],
          [14.18,119.55]
      ]
        setBounds(LuzonBounds)
        map.fitBounds(LuzonBounds)
      },
    }),
    [map],
    )
    const Itogon= useMemo(
      () => ({
      click() {
        const Itogonbound = [
          [16.232311, 120.592890],
          [16.475534, 120.746840]
        ]
        setBounds(Itogonbound)
        map.fitBounds(Itogonbound)
      },
    }),
    [map],
    )

    const Visayas= useMemo(
      () => ({
      click() {
        const VisayasBounds = [
          [9.731919, 121.108217],
          [13.324049, 124.122398]
        ]
        setBounds(VisayasBounds)
        map.fitBounds(VisayasBounds)
      },
    }),
    [map],
    )

    const Mindanao= useMemo(
      () => ({
      click() {
        const MindaBounds = [
          [6.411034, 123.734324],
          [9.233019, 126.517531]
        ]
        setBounds(MindaBounds)
        map.fitBounds(MindaBounds)
      },
    }),
    [map],
    )

    const Phil= useMemo(
      () => ({
      click() {
        const PhilBounds = [
          [5.465192, 116.944479],
          [18.830924, 126.768633]
        ]
        setBounds(PhilBounds)
        map.fitBounds(PhilBounds)
      },
    }),
    [map],
    )

    const [bounds, setBounds] = useState(PhilBounds);

    return (
        <Fragment>
       <Rectangle
        bounds={PhilBounds}
        eventHandlers={Phil}
        pathOptions={style}
        // pathOptions={bounds === PhilBounds ? redColor : whiteColor}
      />

        <Rectangle
          bounds={LuzonBounds}
          eventHandlers={Luzon}
          pathOptions={style}
          // pathOptions={bounds === Itogonbounds ? redColor : whiteColor}
        />
        <Rectangle
          bounds={VisayasBounds}
          eventHandlers={Visayas}
          pathOptions={style}
          // pathOptions={bounds === VisayasBounds ? redColor : whiteColor}
        />

        <Rectangle
          bounds={MindaBounds}
          eventHandlers={Mindanao}
          pathOptions={style}
          // pathOptions={bounds === MindaBounds ? redColor : whiteColor}
        />

        <Rectangle
          bounds={Itogonbound}
          eventHandlers={Itogon}
          pathOptions={style}
          // pathOptions={bounds === Itogonbound ? redColor : whiteColor}
        />
        
        
      </Fragment>
    )
}

export default ItogonBounds
