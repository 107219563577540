import { MapContainer, TileLayer } from 'react-leaflet';
import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet';
import {useEffect} from 'react'
import './FormControl1.css'
import legend from '../../assets/img/content/Home/legend.png'

//aws AppSync
import { compose, graphql } from 'react-apollo';


function FormControl1(props) {

    
   
    const context = useLeafletContext()


    useEffect(() => {

        const container = context.layerContainer || context.map

        L.Control.Form1 = L.Control.extend({
			
            onAdd: function () {

                var form1 = L.DomUtil.create('img');
				form1.src = legend;	  
				return form1;
            },
			
            // onRemove: function () {
			// 	L.DomUtil.remove(L.Control.Form1)
            // }
        });

        const control = new L.Control.Form1(props.position)
        container.addControl(control)
	

        return () => {
            container.removeControl(control)
        }
    })

    return null
}

export default FormControl1;

