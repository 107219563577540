import React from 'react';

import { Button } from 'reactstrap';

// Leaflet
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import icon from '../../assets/img/marker/base.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
let DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow,
	iconSize: [30, 30],
	shadowSize: [30, 50],
	iconAnchor: [15, 30],
	shadowAnchor: [0, 50],
	popupAnchor: [0, -20]
});
// L.Marker.prototype.options.icon = DefaultIcon;

const MapPopup = (props) => {
	return (
		<Marker position={props.point} icon={DefaultIcon}>
			<Popup>
				<h1>Base Station</h1>
				<p>
					Name: {props.name}
					<br />
					Address: {props.address.House + ', ' + props.address.Street + ', ' + props.address.District}
					<br />
					<Button size='sm' color='primary' className='mt-3'>
						View more
					</Button>
				</p>
			</Popup>
		</Marker>
	);
};

export default MapPopup;
