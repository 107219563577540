import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

const LinkHolder = ({ links }) => {
  return links.map(({ link, label },index) => (
    <Row key={`footerLink_${index}`}>
      <Col xs='12'>
        <Link to={link} className='text-dark small'>
          <i className='fa fa-angle-right pr-2' />
          {label}
        </Link>
      </Col>
    </Row>
  ));
};

export default LinkHolder;
