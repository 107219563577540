import React, { Fragment, useState } from 'react';
import moment from 'moment-timezone';
import { NavbarToggler, NavbarText, Collapse, Navbar, NavItem, NavLink, Nav } from 'reactstrap';

import classes from './LandingNavbar.module.css';

import scarpLogo from 'assets/img/brand/scarplogo.png';
import DOST from 'assets/img/brand/dost.png';
import PCIEERD from 'assets/img/brand/PCIEERD.png';
import NERC from 'assets/img/brand/NERC.jpg';
import Newton from 'assets/img/brand/Newton.png';
import Mapua from 'assets/img/brand/mapua.png';
import Exeter from 'assets/img/brand/exeter.png';

const dateFormat = 'dddd, MMMM Do YYYY, h:mm:ss a z';

const LandingNavbar = () => {
  const [isTopOpen, setIsTopOpen] = useState(false)
  const [dateTime, setDateTime] = useState(moment().tz('Asia/Manila').format(dateFormat));

  setInterval(() => {
    setDateTime(moment().tz('Asia/Manila').format(dateFormat));
  }, 1000);

  return (
    <Fragment>
      <Navbar expand='md' className={`small py-1 ${classes.topNav}`}>
        <NavbarText>{dateTime}</NavbarText>
        <NavbarToggler className='text-white' onClick={() => setIsTopOpen(x => !x)} ><i className='fa fa-bars' /></NavbarToggler>
        <Collapse navbar isOpen={isTopOpen}>
          <Nav className='ml-auto'>
            <NavItem>
              <NavLink className={classes.link} href='/'>
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classes.link} href='/aboutus'>
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classes.link} href='/productsAndServices'>
                Products/Services
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classes.link} href='/publications'>
                Publications
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classes.link} href='/contactus'>
                Contact Us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classes.link} href='/login'>
                Portal
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>

      <Navbar expand='md' className='small text-dark d-none d-lg-flex'>
        <img alt='Scarp' src={scarpLogo} className={classes.scarpLogo} />
        <NavbarText className='h4'> Simulating Cascading Rainfall-Triggered Landslide Hazards in the Philippines</NavbarText>
        <Collapse navbar>
          <Nav className='ml-auto' navbar>
            <NavItem className={classes.partnerWrapper}>
              <img alt='Mapúa University' src={Mapua} className='img-fluid' />
            </NavItem>
            <NavItem className={classes.partnerWrapper}>
              <img alt='Exeter' src={Exeter} className='img-fluid' />
            </NavItem>
            <NavItem className={classes.partnerWrapper}>
              <img alt='DOST' src={DOST} className='img-fluid' />
            </NavItem>
            <NavItem className={classes.partnerWrapper}>
              <img alt='PCIEERD' src={PCIEERD} className='img-fluid' />
            </NavItem>
            <NavItem className={classes.partnerWrapper}>
              <img alt='NERC' src={NERC} className='img-fluid' />
            </NavItem>
            <NavItem className={classes.partnerWrapper}>
              <img alt='Newton' src={Newton} className='img-fluid' />
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </Fragment>
  );
};

export default LandingNavbar;
