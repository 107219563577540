import React, { useState, useEffect } from 'react';
// node.js library that concatenates classes (strings)
import classnames from 'classnames';
// javascipt plugin for creating charts
import Chart from 'chart.js';
// reactstrap components
import { Card, CardHeader, CardBody, NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

//aws AppSync
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';

// core components
import { chartOptions, parseOptions } from '../../variables/charts.js';

// import RainChart from '../../variables/rainChart'

import Header from '../../components/Headers/Header.js';
import RainChart from '../../variables/rainChart';
import ImageSensor from '../../variables/ImageSensor';
import DataTable from '../../components/DataTable/DataTable';

import TemperatureChart from '../../variables/temperatureChart';

const sensor = gql`
	query MyQuery {
		queryMountainDews2SBySitebyDate(Site: "MD1", first: 500) {
			items {
				Site
				BatteryLevel
				Date
				SoilMoisture1
				SoilMoisture2
				SoilMoisture3
			}
		}
	}
`;

const Index = (props) => {
	const [activeNav, setActiveNav] = useState(1);
	const [chartExample1Data, setChartExample1Data] = useState('data1');

	const [soilMoisture, setSoilMoisture] = useState([]);

	if (window.Chart) {
		parseOptions(Chart, chartOptions());
	}

	const toggleNavs = (e, index) => {
		e.preventDefault();
		setActiveNav(index);
		setChartExample1Data(chartExample1Data === 'data1' ? 'data2' : 'data1');
	};

	useEffect(() => {
		const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
		let rows = props.todos.map((item) => {
			return [
				item.Site,
				new Date(item.Date).toLocaleDateString('en-US', options),
				item.BatteryLevel,
				item.SoilMoisture1,
				item.SoilMoisture2,
				item.SoilMoisture3
			];
		});

		setSoilMoisture(rows);
	}, [props.todos]);

	return (
		<>
			<Header />
			{/* Page content */}
			<Container className='mt--7' fluid>
				<Row>
					<Col className='mb-5 mb-xl-0' xl='8'>
						<Card className='bg-gradient-default shadow'>
							<CardHeader className='bg-transparent'>
								<Row className='align-items-center'>
									<div className='col'>
										<h6 className='text-uppercase text-light ls-1 mb-1'>Overview</h6>
										<h2 className='text-white mb-0'>Rainfall Value mm/hr</h2>
									</div>
									<div className='col'>
										<Nav className='justify-content-end' pills>
											<NavItem>
												<NavLink
													className={classnames('py-2 px-3', {
														active: activeNav === 1
													})}
													href='#pablo'
													onClick={(e) => toggleNavs(e, 1)}>
													<span className='d-none d-md-block'>Month</span>
													<span className='d-md-none'>M</span>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames('py-2 px-3', {
														active: activeNav === 2
													})}
													data-toggle='tab'
													href='#pablo'
													onClick={(e) => toggleNavs(e, 2)}>
													<span className='d-none d-md-block'>Week</span>
													<span className='d-md-none'>W</span>
												</NavLink>
											</NavItem>
										</Nav>
									</div>
								</Row>
							</CardHeader>
							<CardBody>
								{/* Chart */}
								<RainChart />
							</CardBody>
						</Card>
					</Col>
					<Col xl='4'>
						<Card className='shadow'>
							<CardHeader className='bg-transparent'>
								<Row className='align-items-center'>
									<div className='col'>
										<h6 className='text-uppercase text-muted ls-1 mb-1'>Temperature Graph</h6>
										<h2 className='mb-0'>in °C</h2>
									</div>
								</Row>
							</CardHeader>
							<CardBody>
								{/* Chart */}
								<TemperatureChart />
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row className='mt-5'>
					<Col xl='4'>
						<ImageSensor />
					</Col>
					<Col className='mb-5 mb-xl-0' xl='8'>
						<DataTable
							title={'Base Station'}
							columns={[
								'Site',
								'Date',
								'Battery Level (V)',
								'Soil Moisture 1 (15cm)',
								'Soil Moisture 2 (30cm)',
								'Soil Moisture 3 (50cm)'
							]}
							rows={soilMoisture}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default compose(
	graphql(sensor, {
		options: {
			fetchPolicy: 'cache-and-network'
		},
		props: (props) => ({
			todos: props.data.queryMountainDews2SBySitebyDate
				? props.data.queryMountainDews2SBySitebyDate.items
				: []
		})
	})
)(Index);
