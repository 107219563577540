import React, { Fragment } from 'react';
import './Journals.css'

import { Card, CardBody, CardFooter, CardTitle, Col, Container, Row } from 'reactstrap';

function Journals() {
  return(
    <Fragment>
			<Row>
				<Col className='align-items-center text-center'>
					<Container>
						<h1 className='page-header overflow-hiddens mob'>PUBLICATIONS</h1>
						
					</Container>
				</Col>
			</Row>
			<Row className='mb-5 px-3 mx-5'>
				<Col>
				<Card className='card1'>
					<a style={{'fontSize': '10px'}}>Nat. Hazards Earth Syst. Sci., 21, 1531–1550, 2021 https://doi.org/10.5194/nhess-21-1531-2021</a>
					<a style={{'fontSize': '10px'}}>© Author(s) 2021. This work is distributed underthe Creative Commons Attribution 4.0 License.</a>
					<CardTitle className='text-center mt-5 font-weight-bold'>The Role of Geomorphology, Rainfall and Soil Moisture in the Occurrence of Landslides Triggered by 2018 Typhoon Mangkhut in the Philippines</CardTitle>
					<CardBody>
						<div className='text-center'>
						 Clàudia Abancó <sup>1</sup>, Georgina L. Bennett <sup>1</sup>, Adrian J. Matthews <sup>2</sup>, Mark A. Matera<sup>3</sup>, Fibor J. Tan <sup>3</sup>
						</div>
						<div className='text-center small'>
						<sup>1</sup>College of Life and Environmental Sciences, University of Exeter, Exeter, EX4 4RJ, United Kingdom
						</div>
						<div className='text-center small'>
						<sup>2</sup>Centre for Ocean and Atmospheric Sciences, School of Environmental Sciences and School of Mathematics, University of East Anglia, Norwich, NR4 7TJ, United Kingdom
						</div>
						<div className='text-center small'>
							<sup>3</sup>School of Civil, Environmental and Geological Engineering, Mapúa University, Manila, Philippines
						</div>
					
						
						<div className='text-center'>
							<br></br>
							<a className='font-italic'>Abstract</a>
							 <a className='text-justify float-left'>
							 Rainfall-triggered landslides are common in places where typhoons are frequently occurring such as the Philippines which experiences at least 20 typhoons per year. Heavy rainfalls saturate soil and affects the stability of the slopes causing landslides. A catastrophic rainfall-triggered landslide happened in Brgy. Ucab, Itogon, Benguet in Luzon Island which occurred during the Tropical Cyclone Mangkhut or locally known as Typhoon Ompong on September 15, 2018 having 94 casualties. The study focused on slope stability analysis of rainfall-triggered landslides to help improve the current understanding of the rainfall-triggered landslide in the area. The findings of the study can be used in modeling rainfall-triggered landslide hazard maps. The slope model of the landslide in Itogon was modelled using GEO5 2020. Both the analytical method of the Simplified Bishop Method and the numerical solution provided in the software GEO5 2020 were used in analyzing the slope stability of the landslide. The slope stability analysis showed that the slope was unstable upon considering factors such as its geological and hydrological characteristics agreeing with the disastrous event that transpired.
							 </a>	
						</div>
					</CardBody>
				</Card>
				</Col>
			</Row>
			<Row className='mb-5 px-3 mx-5'>
				<Col>
				<Card className='card1'>
					<a style={{'fontSize': '10px'}}>2021 IEEE Conference on Technologies for Sustainability (SusTech)</a>
					<a style={{'fontSize': '10px'}}>Conference Location: Irvine, CA, USA</a>
					<a style={{'fontSize': '10px'}}>DOI: 10.1109/SusTech51236.2021.9467461</a>
					<a style={{'fontSize': '10px'}}>Electronic ISBN:978-1-6654-1927-7</a>
					<a style={{'fontSize': '10px'}}>ISBN:978-1-6654-3012-8</a>
					<CardTitle className='text-center mt-5 font-weight-bold'>Slope Stability Analysis as Applied to Rainfall- triggered Landslide in Itogon, Benguet Province, Philippines</CardTitle>
					<CardBody>
						<div className='text-center'>
						Kim Gerard Oliver M. Alcala, Jhoyen Emmanuel Q. Angeles, Paul Kevin I. Lim, Jocelyn S. Buluran, Fibor J. Tan
						</div>
						<div className='text-center small'>
							School of Civil, Environmental and Geological Engineering, Mapúa University, Manila, Philippines
						</div>
						
						
						<div className='text-center'>
							<br></br>
							<a className='font-italic'>Abstract</a>
							 <a className='text-justify float-left'>
							 In 2018 Typhoon Mangkhut (locally known as Typhoon Ompong) triggered thousands of landslides in the Itogon region of the Philippines. A landslide inventory of the affected region is compiled for the first time, comprising 1101 landslides over a 570 km2 area. The inventory is used to study the geomorphological characteristics and land cover more prone to landsliding as well as the hydrometeorological conditions that led to widespread failure. The results showed that landslides mostly occurred on grassland and wooded slopes of clay superficial geology, predominantly facing east-southeast. Rainfall (Integrated Multi-satellitE Retrievals for Global Precipitation Measurement, IMERG GPM) associated with Typhoon Mangkhut is compared with 33 high-intensity rainfall events that did not trigger regional landslide events in 2018. Results show that landslides occurred during high-intensity rainfall that coincided with the highest soil moisture values (estimated clays saturation point), according to Soil Moisture Active Passive level 4 (SMAP-L4) data. Our results demonstrate the potential of SMAP-L4 and GPM IMERG data for landslide hazard assessment and early warning where ground-based data are scarce. However, other rainfall events in the months leading up to Typhoon Mangkhut that had similar or higher rainfall intensities and also occurred when soils were saturated did not trigger widespread landsliding, highlighting the need for further research into the conditions that trigger landslides in typhoons.
							 </a>	
						</div>
					</CardBody>
				</Card>
				</Col>
			</Row>
	
    </Fragment>
  )
}

export default Journals;
