import React, { useEffect, useState } from 'react';
import {
	Table,
	Card,
	CardHeader,
	CardFooter,
	Pagination,
	PaginationItem,
	PaginationLink,
	Input,
	Row,
	Col,
	Form,
	FormGroup
} from 'reactstrap';
import classes from './DataTable.module.css';

// Required Fields
// theme - ligth, dark
// title
// columns - headers
// content
// footer
// numberOfRows
const DataTable = (props) => {
	//styles
	const [theme, setTheme] = useState('');
	const [styleTable, setStyleTable] = useState('');
	const [styleThead, setStyleThead] = useState('');

	// data
	const [visibleRows, setVisibleRows] = useState([]);
	const [page, setPage] = useState(1);
	const [sort, setSort] = useState([0, true]); //column index , true = asc
	const [numberOfRows, setNumberOfRows] = useState(5);
	const [filters, setFilters] = useState(() => {
		var tempfilter = [];
		for (let i = 0; i < props.columns.length; i++) {
			tempfilter.push('');
		}
		return tempfilter;
	});

	useEffect(() => {
		var rows = props.rows ? props.rows : [];
		// Filter
		rows = rows.filter((row) => {
			var pass = true;
			var str = '';
			for (let i = 0; i < row.length; i++) {
				str = ConvertToString(row[i]).toLowerCase();
				if (!str.includes(filters[i] ? filters[i].toLowerCase() : '')) {
					pass = false;
				}
			}
			return pass;
		});
		// Sort
		rows = rows.sort((a, b) => {
			var str1 = ConvertToString(a[sort[0]]).toUpperCase();
			var str2 = ConvertToString(b[sort[0]]).toUpperCase();
			if (str1 > str2) {
				return sort[1] ? -1 : 1;
			} else if (str1 < str2) {
				return sort[1] ? 1 : -1;
			} else {
				return 0;
			}
		});
		// Page
		var temprows = [];
		for (let i = (page - 1) * numberOfRows; i < numberOfRows * page; i++) {
			if (rows[i]) {
				temprows.push(rows[i]);
			}
		}
		setVisibleRows(temprows);
	}, [props.rows, filters, numberOfRows, page, sort]);

	useEffect(() => {
		if (props.theme === 'dark') {
			setTheme(classes.darkTheme);
			setStyleTable('align-items-center table-flush table-dark');
			setStyleThead('thead-dark');
		}
	}, [props.theme]);

	const Columns = () => {
		var sorticon = '';
		var tableHead = props.columns.map((value, index) => {
			if (index === sort[0]) {
				if (sort[1]) {
					sorticon = <i className='fa fa-caret-down'></i>;
				} else {
					sorticon = <i className='fa fa-caret-up'></i>;
				}
			} else {
				sorticon = '';
			}
			return (
				<th
					scope='col'
					key={'th' + index}
					style={{ cursor: 'pointer' }}
					onClick={(e) => setSort([index, index === sort[0] ? !sort[1] : false])}>
					{value}
					{sorticon}
				</th>
			);
		});
		return (
			<thead className={styleThead}>
				<tr>{tableHead}</tr>
			</thead>
		);
	};

	const TableRows = () => {
		var rows = visibleRows.map((value, index) => {
			return <tr key={'tr' + index}>{TableCells(value)}</tr>;
		});
		return <tbody>{rows}</tbody>;
	};

	const TableCells = (values) => {
		var cells = values.map((value, index) => {
			return <td key={'td' + index}>{value}</td>;
		});
		return cells;
	};

	const Search = () => {
		var style = [];
		if (props.theme === 'dark') {
			style.push('thead-dark');
		}
		var tableHead = props.columns.map((value, index) => {
			return (
				<th scope='col' key={'th' + index}>
					<Input
						type='text'
						name={'txt' + value}
						id={'tblSearch' + index}
						placeholder={value + ' Search'}
						bsSize='sm'
						onChange={(e) => handleFilter(index, e)}
					/>
				</th>
			);
		});
		return (
			<tfoot className={style.join(' ')}>
				<tr>{tableHead}</tr>
			</tfoot>
		);
	};

	const Footer = () => {
		var numPages = [];
		var maxPage = 1;
		if (props.rows) {
			maxPage = Math.ceil(props.rows.length / numberOfRows);
		}
		var pagiStart = page - 1;
		var pagiEnd = page + 1;
		if (page === 1) {
			pagiStart = 1;
			pagiEnd = page + 1;
		}
		if (pagiEnd > maxPage) {
			pagiEnd = maxPage;
		}

		for (let i = pagiStart; i <= pagiEnd; i++) {
			if (page === i) {
				numPages.push(
					<PaginationItem active key={'pagination' + i}>
						<PaginationLink href={'#page' + i} onClick={() => setPage(i)}>
							{i}
						</PaginationLink>
					</PaginationItem>
				);
			} else {
				numPages.push(
					<PaginationItem key={'pagination' + i}>
						<PaginationLink href={'#page' + i} onClick={() => setPage(i)}>
							{i}
						</PaginationLink>
					</PaginationItem>
				);
			}
		}

		return (
			<Pagination className='pagination justify-content-end mb-0' listClassName='justify-content-end mb-0'>
				<PaginationItem disabled={false}>
					<PaginationLink href='#first' onClick={() => setPage(1)}>
						<i className='fas fa-angle-double-left' />
						<span className='sr-only'>Previous</span>
					</PaginationLink>
				</PaginationItem>
				{numPages}
				<PaginationItem>
					<PaginationLink href='#last' onClick={() => setPage(maxPage)}>
						<i className='fas fa-angle-double-right' />
						<span className='sr-only'>Next</span>
					</PaginationLink>
				</PaginationItem>
			</Pagination>
		);
	};

	const handleFilter = (index, e) => {
		var tempFilter = filters;
		tempFilter[index] = e.target.value;
		setFilters([...tempFilter]);
	};

	const ConvertToString = (item) => {
		var output = '';
		switch (typeof item) {
			case 'number':
				output = item.toString();
				break;
			case 'object': {
				for (let i = 0; i < item.props.children.length; i++) {
					if (typeof item.props.children[i] === 'string') {
						output += item.props.children[i];
					}
				}
				break;
			}
			case 'string':
				output = item;
				break;

			default:
				output = '';
				break;
		}
		return output;
	};

	return (
		<Card className={theme}>
			<CardHeader className='bg-transparent border-0'>
				<h3 className='mb-0'>{props.title}</h3>
			</CardHeader>
			<Table className={styleTable} responsive>
				{Columns()}
				{TableRows()}
				{Search()}
			</Table>

			<CardFooter className='bg-transparent py-3'>
				<Row>
					<Col className='text-nowrap'>
						<Form inline>
							<FormGroup>
								Show
								<Input
									type='select'
									title='Number of Entries'
									className='mx-1'
									bsSize='sm'
									onChange={(e) => setNumberOfRows(e.target.value)}>
									<option>5</option>
									<option>10</option>
									<option>25</option>
									<option>50</option>
									<option>100</option>
								</Input>
								entries
							</FormGroup>
						</Form>
					</Col>
					<Col>{Footer()}</Col>
				</Row>
			</CardFooter>
		</Card>
	);
};

export default DataTable;
