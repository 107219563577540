import Dashboard from './views/Admin/Dashboard';
import Login from './views/Guest/Login';

// Tables
import Users from './views/Admin/Users';
import BaseStation from './views/Admin/BaseStation';
import Site from './views/Admin/Site';
import Sensor from './views/Admin/Sensor';
import Statistics from './views/Admin/Statistics';
import Map from './views/Guest/Map'
import DownloadableMap from './views/Admin/DownloadableMap';

// import Profile from 'views/examples/Profile.js';
// import Maps from 'views/examples/Maps.js';
// import Register from 'views/examples/Register.js';
// import Tables from 'views/examples/Tables.js';
// import Icons from 'views/examples/Icons.js';

var routes = [
	{
		path: '/map',
		name: 'Map',
		icon: 'ni ni-tv-2 text-primary',
		component: Map,
		layout: '/admin'
	},
	{
		path: '/index',
		name: 'Dashboard',
		icon: 'ni ni-tv-2 text-primary',
		component: Dashboard,
		layout: '/admin'
	},
	{
		path: '/downloadablemaps',
		name: 'Landslide Susceptibility',
		icon: 'ni ni-single-02 text-yellow',
		component: DownloadableMap,
		layout: '/admin'
	}
	// {
	// 	path: '/users',
	// 	name: 'Users',
	// 	icon: 'ni ni-single-02 text-yellow',
	// 	component: Users,
	// 	layout: '/admin'
	// },
	// {
	// 	path: '/map',
	// 	name: 'Map',
	// 	icon: 'ni ni-world text-success',
	// 	component: Map,
	// 	layout: '/admin'
	// },
	// {
	// 	path: '/basestation',
	// 	name: 'Base Stations',
	// 	icon: 'ni ni-building text-blue',
	// 	component: BaseStation,
	// 	layout: '/admin'
	// },
	// {
	// 	path: '/site',
	// 	name: 'Sites',
	// 	icon: 'ni ni-pin-3 text-orange',
	// 	component: Site,
	// 	layout: '/admin'
	// },
	// {
	// 	name: 'Sensors',
	// 	icon: 'ni ni-atom text-red',
	// 	sub: [
	// 		{
	// 			path: '/sensors/temperature',
	// 			name: 'Temperature',
	// 			component: Sensor,
	// 			layout: '/admin'
	// 		},
	// 		{
	// 			path: '/sensors/humidity',
	// 			name: 'Humidity',
	// 			component: Sensor,
	// 			layout: '/admin'
	// 		},
	// 		{
	// 			path: '/sensors/rainrate',
	// 			name: 'Rain Rate',
	// 			component: Sensor,
	// 			layout: '/admin'
	// 		},
	// 		{
	// 			path: '/sensors/soilmoisture/15cm',
	// 			name: 'Soil Moisture 15CM',
	// 			component: Sensor,
	// 			layout: '/admin'
	// 		},
	// 		{
	// 			path: '/sensors/windspeed',
	// 			name: 'Wind Speed',
	// 			component: Sensor,
	// 			layout: '/admin'
	// 		}
	// 	]
	// },
	// {
	// 	path: '/admin/statistics',
	// 	name: 'Statistics',
	// 	icon: 'ni ni-sound-wave text-info',
	// 	component: Statistics,
	// 	layout: '/admin'
	// },
	// {
	// 	path: '/register',
	// 	name: 'Settings',
	// 	icon: 'ni ni-settings-gear-65 text-pink',
	// 	component: Login,
	// 	//component: Register,
	// 	layout: '/auth'
	// }
];
export default routes;
