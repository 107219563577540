import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// aos Scroll Animation
import AOS from 'aos';
import 'aos/dist/aos.css';

// core components
import LandingNavbar from '../components/Navbars/LandingNavbar';
import LandingFooter from '../components/Footers/LandingFooter.js';

//redirects
import Home from '../views/Guest/Home';
import AboutUs from '../views/Guest/AboutUs';
import Portfolio from '../views/Guest/Portfolio';
import ContactUs from '../views/Guest/ContactUs';
import Login from '../views/Guest/Login';
import AboutLogo from '../views/Guest/AboutLogo';
import ProductsServices from '../views/Guest/ProductsServices'
import Journals from '../views/Guest/Journals'

import '../assets/css/argon-dashboard-react.min.css';
import '../assets/css/argon-dashboard-react.css.map';

document.body.classList.remove('bg-default');
AOS.init();

const Landing = (props) => (
	<Fragment>
		<div className='main-content'>
			<LandingNavbar />
			<Switch>
				<Route path={props.match.url + 'home'} render={() => <Home />} />
				<Route path={props.match.url + 'aboutus'} render={() => <AboutUs />} />
				<Route path={props.match.url + 'aboutLogo'} render={() => <AboutLogo />} />
				<Route
					path={props.match.url + 'portfolio'}
					render={() => <Portfolio />}
				/>
				<Route path={props.match.url + 'productsAndServices'} render={() => <ProductsServices {...props}/>} />
				<Route path={props.match.url + 'publications'} render={() => <Journals {...props}/>} />
				<Route
					path={props.match.url + 'contactus'}
					render={() => <ContactUs />}
				/>
				<Route path={props.match.url + 'login'} render={() => <Login {...props} />} />
				<Redirect from='*' to='/home' />
			</Switch>
		</div>
		<LandingFooter />
	</Fragment>
);

export default Landing;
