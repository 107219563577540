import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminNavbar from '../components/Navbars/AdminNavbar.js';
import AdminFooter from '../components/Footers/AdminFooter.js';
import Sidebar from '../components/Sidebar/Sidebar.js';
// Images
// import Logo from '../assets/img/brand/argon-react.png';

import '../assets/css/argon-dashboard-react.min.css';
import '../assets/css/argon-dashboard-react.css.map';

import routes from '../routes';
const Admin = (props) => {
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.sub) {
				return prop.sub.map((v, k) => {
					return (
						<Route path={v.layout + v.path} component={v.component} key={k} />
					);
				});
			} else {
				if (prop.layout === '/admin') {
					return (
						<Route
							path={prop.layout + prop.path}
							component={prop.component}
							key={key}
						/>
					);
				} else {
					return null;
				}
			}
		});
	};

	const getBrandText = (path) => {
		for (let i = 0; i < routes.length; i++) {
			if (
				props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
				-1
			) {
				return routes[i].name;
			}
		}
		return 'Brand';
	};


	document.body.classList.remove('bg-default');
	return (
		<Fragment>
			<Sidebar routes={routes} />
			<div className='main-content'>
				<AdminNavbar
					{...props}
					brandText={getBrandText(props.location.pathname)}
				/>
				<Switch>
					{getRoutes(routes)}
					<Redirect from='*' to='/admin/index' />
				</Switch>
				<Container fluid>
					<AdminFooter />
				</Container>
			</div>
		</Fragment>
	);
};

export default Admin;
