import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './AWSConfig.json'
import Amplify from 'aws-amplify';

//reactstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//Argon Theme
import './assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';

//Global css
import './assets/css/jumee.css';

//AWS AppSync
import Client from 'aws-appsync'
import { ApolloProvider } from 'react-apollo'
import { Rehydrated } from 'aws-appsync-react'
import AppSync from './AppSync';

Amplify.configure({
	Auth: {
	mandatorySignId: true,
	region: config.cognito.REGION,
	userPoolId: config.cognito.USER_POOL_ID,
	userPoolWebClientId: config.cognito.APP_CLIENT_ID
	}
  })

  const client = new Client({
	url: AppSync.aws_appsync_graphqlEndpoint,
	region: AppSync.aws_appsync_region,
	auth: {
	  type: AppSync.aws_appsync_authenticationType,
	  apiKey: AppSync.aws_appsync_apiKey
	}
  });
  
  const WithProvider = () => (
	<ApolloProvider client={client}>
	  <Rehydrated>
		<App />
	  </Rehydrated>
	</ApolloProvider>
	)
  
  ReactDOM.render(<WithProvider />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
