import React, { Fragment } from 'react';

// reactstrap components
import { Card, CardBody, NavLink, Col, Container, Row } from 'reactstrap';

import DOST from '../../assets/img/brand/dost.png';
import PCIEERD from '../../assets/img/brand/PCIEERD.png';
import Newton from '../../assets/img/brand/Newton.png';
import NERC from '../../assets/img/brand/NERC.jpg';
import Mapua from '../../assets/img/brand/mapua.png';
import Exeter from '../../assets/img/brand/exeter.png';
import UEA from '../../assets/img/brand/UEA.png';

import PAGASA from '../../assets/img/brand/PAGASA.png';
import MGB from '../../assets/img/brand/MGB.png';
import Philvocs from '../../assets/img/brand/Philvocs.png';
import DPWH from '../../assets/img/brand/DPWH.png';

import UPD from '../../assets/img/brand/UPD.png';
import UGlasgow from '../../assets/img/brand/UGlasgow.png';
import Namria from '../../assets/img/brand/namria.png';
import BSWM from '../../assets/img/brand/BSWM.png';
import NWRB from '../../assets/img/brand/NWRB.png';
import TheWeatherPhil from '../../assets/img/brand/TheWeatherPhil.png';

import ScarpLogo from '../../assets/img/brand/scarplogo.png';

//Philippine Team
import Tan from '../../assets/img/content/AboutUs/Tan.jpg';
import Sarah from '../../assets/img/content/AboutUs/sarah.jpg';
import Roan from '../../assets/img/content/AboutUs/roan.jpg';
import Mark from '../../assets/img/content/AboutUs/Mark.jpg';
import Paolo from '../../assets/img/content/AboutUs/Paolo.jpg';
import Jeremy from '../../assets/img/content/AboutUs/Jeremy.jpg';
import Jane from '../../assets/img/content/AboutUs/Jane.jpg';

import classesAboutUs from './AboutUs.module.css';
import { VideoOverlay } from 'react-leaflet';
const AboutUs = (props) => (
	<Fragment>
		<section className='justify-content-center bg-white py-20vh'>
			<Container>
				<Row>
					<Col data-aos='fade-left'>
						<h1>About us</h1>
						<hr className={classesAboutUs.hr1} />
						<p className='text-justify'>
							The project entitled, “Simulating Cascading Rainfall-triggered Landslide Hazards in the
							Philippines (SCaRP)” is funded by the Department of Science and Technology (DOST-PCIEERD) and UK
							NERC through Newton Agham. The project aims to understand the relationship between landslide
							events, meteorological drivers, and preconditioning landslide factors in the country in order to
							predict landslides and relate them to sediment delivery rates to nearby river systems.
						</p>
						<h1  className={classesAboutUs.subtitle1}>About the Logo</h1>
						<hr className={classesAboutUs.hr1} />
						<p className='text-justify'>
							The SCaRP logo conveys the rainfall-induced landslide as it's objective. The logo starts at it's acronym S for the simulating followed by C that stands for Cascading and depicted as an scarp and the placement of A that conveys as the pre-landslide which is an important symbol to quantify the sediment delivery by computing the difference between the pre and post landslide events. The A also may serve as the placement of slope protection for the identified location of high risk rainfall-induced landslide that is one of the main objectives of SCaRP project. The R stands for the Rainfall-Induced Landslide and P is the Philippines as the project sites are located within the entire Philippines. Hence, SCaRP logo wholly describe the Simulating and Cascading of Rainfall-induced Landslide of the Philippines Project.
						</p>
						<h1  className={classesAboutUs.subtitle1}>Components</h1>
						<hr className={classesAboutUs.hr1} />
						<h2  className={classesAboutUs.subtitle2}>ROYAL Component : Rainfall-induced Landslide </h2>
						<p className='text-justify ml-5'>
							The relationship between the preconditioning factors of the areas, meteorological drivers, and landslide events are determined. The project selected study areas in the Philippines that experience rainfall-triggered landslides (RTL) on a regular basis. Other areas were selected due to historical RTLs that are deemed catastrophic in nature due to the damages in infrastructures and casualties inflicted. Study areas are located in varying Philippine micro-climate conditions as described by the 1951 – 2010 Modified Corona’s Classified Climate Classification of the Philippine Atmospheric, Geophysical and Astronomical Services Administration (PAGASA).
						</p>
						<p className='text-justify ml-5'>
							Furthermore, The study areas selected are (1) Vintar, Ilocos Norte; (2) Ilagan City, Isabela; (3) Itogon, Benguet; (4) Real-Infanta-General Nakar (REINA), Quezon; (5) Biliran Province; (6) Cebu City, Cebu; and (7) Malaybalay City, Bukidnon.
						</p>
						
						<h2  className={classesAboutUs.subtitle2}>C2 Component: Sediment Tranport Rate and Cascading Effect of Landslide </h2>
						<p className='text-justify ml-5'>
							The study provided sediment delivery rates in the overland and river channel network and simulated mud-flood flow by quantifying sediments as well as the identification of rheologic parameters to establish realistic results to further discover potential sedimentation problems within the Antamok River Basin in Itogon, Benguet, Philippines.
						</p>
						<h2  className={classesAboutUs.subtitle2}>Mt. DEWS Sensor Component: Sensor Implementation</h2>
						<p className='text-justify ml-5'>
							Pilot area for the early warning sensor system is placed in the municipality of Itogon. Four (4) automated weather stations are placed to have a better understanding of rainfall-induced landslide parameters. This component has built an automated weather station capable of reading landslide trigger factors and sends the data for transformation and interpretation. The sensors are specifically design for the DOST - SCaRP Project that is capable of monitoring external factors such as weather conditions and maintaining continous power without grid. It is also equipped with an SMS troubleshooting system to remotely debug the system. Each sensor is able to read and send scalable, reliable, and secured environmental data.
						</p>
						<h2  className={classesAboutUs.subtitle2}>Mt. DEWS Web Component: Web Development</h2>
						<p className='text-justify ml-5'>
							This component aims to disseminate information as early and as wide as possible. It represents the first step in the implementation of a complete early warning system and allows the decision-makers to the latest practical solution for disaster mitigation. Methodologies and techniques are integrated to the cloud services for scalable, secured, and continuous web services.
						</p>
					</Col>
				</Row>
			</Container>
		</section>
		<section className='justify-content-center bg-white py-10vh px-1px'>
			<Container className={classesAboutUs.Container1}>
				<Row>
					<Col>
					<h1  className={classesAboutUs.subtitle1}>Meet the Philippine Team </h1>
						<hr className={classesAboutUs.hr1} />
					</Col>
				</Row>
			</Container>
			<Container>
				<Row>
					<Col className='card-profile shadow h-60 hover-up'>
						<Card>
							<CardBody>
								<div className={classesAboutUs.ProfilePic}>
									<img src={Tan} alt='Fibor Tan' className={classesAboutUs.imgPic} />
								</div>
								<div className={classesAboutUs.ProfileDesc}>
									<i class='fas fa-lightbulb fa-2x text-yellow'></i>
									<h3 className='text-indigo'>Engr. Fibor J. Tan</h3>
									<div>
										is the Project Leader of Mapúa - SCaRP Project and a visionary of a sustainable
										environment. He is an Associate Professor of Mapúa University in Manila, Philippines and a
										candidate in PhD in Civil Engineering major in Water Resources Engineering.
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className='card-profile shadow h-60 hover-up'>
						<Card>
							<CardBody>
								<div className={classesAboutUs.ProfilePicUR2}>
									<img src={Mark} alt='Mark Matera' className={classesAboutUs.imgPic} />
								</div>
								<div className={classesAboutUs.ProfileDesc}>
									<i class='fas fa-globe-europe fa-2x text-gray '></i>
									<h3 className='text-green'>Mr. Mark Anthony M. Matera</h3>
									<div>
										is a University Researcher II of Mapúa - SCaRP Project, He is also a Geologist and a
										Faculty Member of School of Civil, Environmental and Geological Engineering (CEGE), Mapúa
										University, Manila, Philippines.
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className='card-profile shadow h-60 hover-up'>
						<Card>
							<CardBody>
								<div className={classesAboutUs.ProfilePicUR2}>
									<img src={Sarah} alt='Sarah Bentir' className={classesAboutUs.imgPic} />
								</div>

								<div className={classesAboutUs.ProfileDesc}>
									<i class='fas fa-laptop fa-2x text-dark '></i>
									<h3 className='text-green'>Ms. Sarah Alma P. Bentir</h3>
									<div>
										is a University Researcher II of Mapúa - SCaRP Project, she is a graduate of MS in
										Computer Science and currently inclined to web development and GIS Remote Sensing.
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				<Row>
				<Col className='card-profile shadow h-60 hover-up'>
						<Card>
							<CardBody>
								<div className={classesAboutUs.ProfilePicUR1}>
									<img src={Paolo} alt='Paolo Antazo' className={classesAboutUs.imgPic} />
								</div>
								<div className={classesAboutUs.ProfileDesc}>
									<i class='fas fa-hard-hat fa-2x text-indigo '></i>
									<h3 className='text-red'>Engr. Paolo A. Antazo</h3>
									<div>
										is a University Researcher I of Mapúa - SCaRP Project, he is a candidate in MS in Civil
										Engineering major in Water Resources Engineering and currently involved in sediment
										deposits modeling.
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className='card-profile shadow h-60 hover-up'>
						<Card>
							<CardBody>
								<div className={classesAboutUs.ProfilePicUR1}>
									<img src={Roan} alt='Roan Amado' className={classesAboutUs.imgPic} />
								</div>
								<div className={classesAboutUs.ProfileDesc}>
									<i class='fas fa-globe fa-2x text-pink '></i>
									<h3 className='text-red'>Ms. Rose Ann F. Amado</h3>
									<div>
										is a University Researcher I of Mapúa - SCaRP Project, she graduated with a degree of BS
										in Geography. She is currently involved in GIS and Hydrologic Modeling.
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className='card-profile shadow h-60 hover-up'>
						<Card>
							<CardBody>
								<div className={classesAboutUs.ProfilePicUR1}>
									<img src={Jeremy} alt='Jeremy De Leon' className={classesAboutUs.imgPic} />
								</div>
								<div className={classesAboutUs.ProfileDesc}>
									<i class='fas fa-assistive-listening-systems fa-2x text-blue '></i>
									<h3 className='text-red'>Mr. Jeremy C. De Leon</h3>
									<div>
										is a University Researcher I of Mapúa - SCaRP Project, he focuses on the development and
										electronic design of the sensor's system. He explores the use of rapid additive
										manufacturing for Disaster Risk Reduction and Preparedness.
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row  className='d-flex p-2'>
					<Col className='col-6'></Col>
					<Col className='card-profile shadow h-60 hover-up col-6'>
						<Card>
							<CardBody>
								<div className={classesAboutUs.ProfilePicPDO}>
									<img src={Jane} alt='Arnie Jane V. Lucente' className={classesAboutUs.imgPic} />
								</div>
								<div className={classesAboutUs.ProfileDesc}>
									<i class='fas fa-file fa-2x text-orange '></i>
									<h3 className='text-blue'>Ms. Arnie Jane V. Lucente</h3>
									<div>
										is a Clerk IV of Mapúa - SCaRP Project, she is a graduate of BS in Education and NCII
										Computer Hardware Servicing (CHS). She is also a Licensed Educator.
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className='col-6'></Col>
				</Row>
				</Row>
			</Container>
		</section>
		<section className='justify-content-center bg-white py-20vh'>
			<Container>
				<Row>
					<Col className='text-center mb-1' data-aos='fade-right'>
						<h1>Implementing Agency</h1>
					</Col>
				</Row>
				<Row>
					<Col
						sm='6'
						md={{ size: 6, offset: 3 }}
						title='Mapúa University'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='200'>
						<img src={Mapua} alt='Mapúa University' />
					</Col>
				</Row>
				<Row>
					<Col className='text-center mb-1' data-aos='fade-right'>
						<h1>United Kingdom Counterparts</h1>
					</Col>
				</Row>
				<Row>
					<Col
						sm='6'
						md='6'
						title='University of Exeter'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={Exeter} alt='University of Exeter' />
					</Col>
					<Col
						sm='6'
						md='6'
						title='University of East Anglia'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={UEA} alt='University of East Anglia' />
					</Col>
				</Row>
				<Row>
					<Col className='text-center mb-1' data-aos='fade-right'>
						<h1>Funding Agencies</h1>
					</Col>
				</Row>
				<Row>
					<Col
						sm='6'
						md='3'
						title='Department of Science and Technology'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={DOST} alt='Department of Science and Technology' />
					</Col>
					<Col
						sm='6'
						md='3'
						title='DOST Philippine Council for Industry, Energy and Emerging Technology Research and Development'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={PCIEERD} alt='PCIEERD' />
					</Col>
					<Col
						sm='6'
						md='3'
						title='Newton Agham'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={Newton} alt='Newton Agham' />
					</Col>
					<Col
						sm='6'
						md='3'
						title='NERC Science of Environment'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={NERC} alt='NERC Science of Environment' />
					</Col>
				</Row>
				<Row>
					<Col className='text-center mb-1' data-aos='fade-right'>
						<h1>Collaborating Agencies</h1>
					</Col>
				</Row>
				<Row>
					<Col
						sm='6'
						md='3'
						title='DOST Philippine Atmospheric, Geophysical and Astronomical Services Administration'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img
							src={PAGASA}
							alt='Philippine Atmospheric, Geophysical and Astronomical Services Administration'
						/>
					</Col>
					<Col
						sm='6'
						md='3'
						title='DOST Mines and Geo-sciences Burea'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={MGB} alt='Mines and Geo-sciences Bureau' />
					</Col>
					<Col
						sm='6'
						md='3'
						title='DOST Philippine Institute of Volcanology and Seismology'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={Philvocs} alt='Philippine Institute of Volcanology and Seismology' />
					</Col>
					<Col
						sm='6'
						md='3'
						title='Department of Public Works and Highways'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={DPWH} alt='Department of Public Works and Highways' />
					</Col>
				</Row>
				<Row>
					<Col className='text-center mb-1' data-aos='fade-right'>
						<h1>Supporting Agencies</h1>
					</Col>
				</Row>
				<Row>
					<Col
						sm='6'
						md='2'
						title='University of the Philippines Diliman'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={UPD} alt='University of the Philippines Diliman' />
					</Col>
					<Col
						sm='6'
						md='2'
						title='University of Glasgow'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={UGlasgow} alt='University of Glasgow' />
					</Col>
					<Col
						sm='6'
						md='2'
						title='Philippines National Mapping and Resource Information Authority'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={Namria} alt='Philippines National Mapping and Resource Information Authority' />
					</Col>
					<Col
						sm='6'
						md='2'
						title='DA Bureau of Soils and Water Management'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={BSWM} alt='Bureau of Soils and Water Management' />
					</Col>
					<Col
						sm='6'
						md='2'
						title='National Water Resources Board'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={NWRB} alt='National Water Resources Board' />
					</Col>
					<Col
						sm='6'
						md='2'
						title='The Weather Philippines'
						className={classesAboutUs.Team}
						data-aos='fade-up'
						data-aos-delay='300'>
						<img src={TheWeatherPhil} alt='The Weather Philippines' />
					</Col>
				</Row>
			</Container>
		</section>
		<section className='justify-content-center bg-white py-20vh'>
		<Container>
			<Row className='justify-content-center py-5'>
				<Col data-aos='fade-up' data-aos-delay='100'>
					<Card className='card-profile shadow h-100 hover-up'>
						<CardBody className='pt-5'>
							<div className={classesAboutUs.Logo}>
								<img src={ScarpLogo} alt='Royal Logo' className='img-fluid' />
							</div>
							<div className='text-center'>
								<h3>Royal</h3>
								<div>
									Identify multi-landslide events and their meteorological drivers and preconditioning factors
									over the Philippines.
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col data-aos='fade-up' data-aos-delay='200'>
					<Card className='card-profile shadow h-100 hover-up'>
						<CardBody className='pt-5'>
							<div className={classesAboutUs.Logo}>
								<img src={ScarpLogo} alt='C2 Logo' className='img-fluid' />
							</div>
							<div className='text-center'>
								<h3>C2</h3>
								<div>
									Quantify rates of sediment delivery by landslides and transport of sediment in rivers and
									Assess the impact of climate change on landslide and sedimentation in the Philippines
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col data-aos='fade-up' data-aos-delay='300'>
					<Card className='card-profile shadow h-100 hover-up'>
						<CardBody className='pt-5'>
							<div className={classesAboutUs.Logo}>
								<img src={ScarpLogo} alt='MtDews Logo' className='img-fluid' />
							</div>
							<div className='text-center'>
								<h3>MtDews</h3>
								<div>
									Developed the early warning system via a dynamic website portal for landslide hazards in the
									Philippines.
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
		</section>
	</Fragment>
);

export default AboutUs;
