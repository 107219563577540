import React, { useState } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';

// reactstrap components
import {
	Collapse,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Media,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
	NavbarToggler
} from 'reactstrap';

// Images
import Logo from '../../assets/img/brand/scarplogo.png';
import classes from './SidebarPublic.module.css';


const Sidebar = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const closeCollapse = () => setIsOpen(false);

	const createLinks = (routes) => {
		return routes.map((val, key) => {
			if (val.sub) {
				return (
					<UncontrolledDropdown nav inNavbar key={key}>
						<DropdownToggle nav caret>
							<i className={val.icon} />
							{val.name}
						</DropdownToggle>
						<DropdownMenu right>
							<DropdownItem className='noti-title' header tag='div'>
								<h6 className={classes.linkitem + 'text-overflow m-0'}>{val.name}</h6>
							</DropdownItem>
							{createSub(val.sub)}
						</DropdownMenu>
					</UncontrolledDropdown>
				);
			} else {
				return (
					<NavItem key={key}>
						<NavLink
							to={val.layout + val.path}
							tag={NavLinkRRD}
							onClick={closeCollapse}
							activeClassName='active'>
							<i className={val.icon} />
							{val.name}
						</NavLink>
					</NavItem>
				);
			}
		});
	};

	const createSub = (sub) => {
		return sub.map((val, key) => {
			return (
				<DropdownItem key={key} className='p-0'>
					<NavItem>
						<NavLink
							to={val.layout + val.path}
							tag={NavLinkRRD}
							onClick={closeCollapse}
							activeClassName='active'>
							{val.name}
						</NavLink>
					</NavItem>
				</DropdownItem>
			);
		});
	};

	return (
		<Navbar
			className='navbar-vertical fixed-left navbar-light bg-white'
			expand='md'
			id='sidenav-main'>
			<Container fluid>
				{/* Toggler */}
				<NavbarToggler onClick={toggle} />
				{/* Brand */}
				<NavbarBrand className='pt-0'>
					<img alt='SCaRP Logo' className='navbar-brand-img' src={Logo} />
				</NavbarBrand>
				{/* User */}
				<Nav className='align-items-center d-md-none'>
					<UncontrolledDropdown nav>
						<DropdownToggle nav className='nav-link-icon'>
							<i className='ni ni-bell-55' />
						</DropdownToggle>
						<DropdownMenu
							aria-labelledby='navbar-default_dropdown_1'
							className='dropdown-menu-arrow'
							right>
							<DropdownItem>Action</DropdownItem>
							<DropdownItem>Another action</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>Something else here</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
					<UncontrolledDropdown nav>
						<DropdownToggle nav>
							<Media className='align-items-center'>
								<span className='avatar avatar-sm rounded-circle'>
								<i className='fas fa-user' />
									{/* <img
										alt='...'
										src={require('../../assets/img/theme/team-1-800x800.jpg')}
									/> */}
								</span>
							</Media>
						</DropdownToggle>
						<DropdownMenu className='dropdown-menu-arrow' right>
							<DropdownItem className='noti-title' header tag='div'>
								<h6 className='text-overflow m-0'>Welcome!</h6>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-single-02' />
								<span>My profile</span>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-settings-gear-65' />
								<span>Settings</span>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-calendar-grid-58' />
								<span>Activity</span>
							</DropdownItem>
							<DropdownItem to='/admin/user-profile' tag={Link}>
								<i className='ni ni-support-16' />
								<span>Support</span>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
								<i className='ni ni-user-run' />
								<span>Logout</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
				{/* Collapse */}
				<Collapse navbar isOpen={isOpen}>
					{/* Collapse header */}
					<div className='navbar-collapse-header d-md-none'>
						<Row>
							<Col className='collapse-brand' xs='6'>
							
								<Link to='/'>
									<img alt='...' src={Logo} />
								</Link>
								
							</Col>
							<Col className='collapse-close' xs='6'>
								<button
									className='navbar-toggler'
									type='button'
									onClick={toggle}>
									<span />
									<span />
								</button>
							</Col>
						</Row>
					</div>
					{/* Form */}
					<div className={classes.title}>DOST- SCARP Project</div>
					<Form className='mt-4 mb-3 d-md-none'>
						<InputGroup className='input-group-rounded input-group-merge'>
							<Input
								aria-label='Search'
								className='form-control-rounded form-control-prepended'
								placeholder='Search'
								type='search'
							/>
							<InputGroupAddon addonType='prepend'>
								<InputGroupText>
									<span className='fa fa-search' />
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</Form>
					{/* Navigation */}
					<Nav navbar >{createLinks(props.routes)}</Nav>
					{/* Divider */}
					<hr className='my-3' />
					{/* Heading */}
					<h6 className='navbar-heading text-muted'>Documentation</h6>
					{/* Navigation */}
					<Nav className='mb-md-3' navbar>
						<NavItem>
							<NavLink href='https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar'>
								<i className='ni ni-spaceship' />
								Getting started
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href='https://demos.creative-tim.com/argon-dashboard-react/#/documentation/colors?ref=adr-admin-sidebar'>
								<i className='ni ni-palette' />
								Foundation
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href='https://demos.creative-tim.com/argon-dashboard-react/#/documentation/alerts?ref=adr-admin-sidebar'>
								<i className='ni ni-ui-04' />
								Components
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Container>
		</Navbar>
	);
};

export default Sidebar;
