import { useState, useEffect } from 'react';
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';

import AdminLayout from './layouts/Admin.js';
import AuthLayout from './layouts/Auth.js';
import LandingLayout from './layouts/Landing.jsx';

// aos Scroll Animation
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Auth } from 'aws-amplify';
AOS.init();

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!sessionStorage.getItem('userinfo'));
  const [user, isUser] = useState(null);

  const setUser = (users) => {
    isUser(users);
  };

  console.log(isAuthenticated);

  useEffect(() => {
    async function oth() {
      let user;
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.log(`Error received while authenticating: ${error}`);
      }
      if (user) {
        setIsAuthenticated(true);
        setUser(user);
				sessionStorage.setItem('userinfo',user)
      }
    }
    oth();
  }, []);

  const authProps = {
    isAuthenticated,
    user: user,
    setAuthStatus: setIsAuthenticated,
    setUser: setUser
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path='/admin'
          render={(props) =>
            isAuthenticated ? <AdminLayout {...props} auth={authProps} /> : <Redirect to='/login' />
          }
        />
        <Route path='/auth' render={(props) => <AuthLayout auth={authProps} {...props} />} />
        <Route path='/' render={(props) => <LandingLayout auth={authProps} {...props} />} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
