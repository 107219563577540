import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';

const AlertPop = (props) => {
	const cssStyle = {
		position: 'fixed',
		top: '20px',
		right: '20px',
		zIndex: '999'
	};

	useEffect(() => {
		if (props.visible) {
			let autoclose = setTimeout(() => {
				props.toggle(false);
			}, props.timer);
			return () => {
				clearTimeout(autoclose);
			};
		}
	}, [props.visible, props]);

	const handleToggle = () => {
		if (props.toggle || props.toggle === false) {
			props.toggle(false);
		} else {
			props.toggle(!props.visible);
		}
	};

	return (
		<Alert style={cssStyle} color={props.color} isOpen={props.visible} toggle={handleToggle}>
			{props.text}
		</Alert>
	);
};

AlertPop.defaultProps = {
	visible: false,
	timer: 5000,
	color: 'info',
	text: 'Just saying Hi!'
};

export default AlertPop;
