import React, { Fragment } from 'react';

import { Card, CardBody, CardFooter, CardImg, Col, Container, Row} from 'reactstrap';
import Carousel from '../../components/Carousel/Carousel'

import './ProductsServices.css'

//thumbnails
import Image1 from '../../assets/img/maps/lss/itogon.png'

function ProductsServices() {
  return(
      <Fragment>
		 
          <section className='justify-content-center'>
			  <Container>
				<Row>
					  <Col>
					  <h1>Rainfall-Induced Landslide Susceptibility Map</h1>
						<hr className='hr1'/>
					  </Col>
				</Row>
				<Row>
					
					<Col>
						<Card>
							<CardBody>
							<div class="container">
								<img src={Image1} alt='Image1'  className='image img-fluid'/>
							<div class="middle">
								<div class="text">Sign In</div>
							</div>
							</div>
							<div className='text-center' >
								<h3 className='text-dark'>Itogon Landslide Map</h3>
								<div>
									Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
								</div>
							</div>
							</CardBody>
						</Card>
					</Col>
					<Col>
						<Card>
							<CardBody>
							<div class="container">
								<img src={Image1} alt='Image1'  className='image img-fluid'/>
							<div class="middle">
								<div class="text">Sign In</div>
							</div>
							</div>
							<div className='text-center' >
								<h3 className='text-dark'>Itogon Landslide Map</h3>
								<div>
									Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
								</div>
							</div>
							</CardBody>
						</Card>
					</Col>
					<Col>
						<Card>
							<CardBody>
							<div class="container">
								<img src={Image1} alt='Image1'  className='image img-fluid'/>
							<div class="middle">
								<div class="text">Sign In</div>
							</div>
							</div>
							<div className='text-center' >
								<h3 className='text-dark'>Itogon Landslide Map</h3>
								<div>
									Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
								</div>
							</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			  </Container>
			{/* <Row>
				
				<Col className='align-items-center text-center'> */}
					
						{/* <Carousel /> */}
						{/* <h1 className='page-header w-100'>PRODUCTS AND SERVICES</h1>
						<div class='svg-border-rounded text-white'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 144.54 17.34'
								preserveAspectRatio='none'
								fill='currentColor'>
								<path d='M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0'></path>
							</svg>
						</div> */}

					
				{/* </Col>
			</Row> */}
			{/* <Row>
				<Col>
				<h1>About us</h1>
						<hr className='hr1' />
				</Col> */}
				{/* <Col className='card-profile shadow'>
						<Card>
							<CardBody>
								<div style={{'height':'100px'}} className='text-center mb-3' >
									<img src={Image1} alt='Image1'  className='thumbnail img-fluid'/>
								</div>
								<div className='text-center' >
									<h3 className='text-dark'>Itogon Landslide Map</h3>
									<div>
										Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className='card-profile shadow'>
						<Card>
							<CardBody>
								<div style={{'height':'100px'}} className='text-center mb-3' >
									<img src={Image1} alt='Image1'  className='thumbnail img-fluid'/>
								</div>
								<div className='text-center' >
									<h3 className='text-dark'>Itogon Landslide Map</h3>
									<div>
										Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className='card-profile shadow'>
						<Card>
							<CardBody>
								<div style={{'height':'100px'}} className='text-center mb-3' >
									<img src={Image1} alt='Image1'  className='thumbnail img-fluid'/>
								</div>
								<div className='text-center' >
									<h3 className='text-dark'>Itogon Landslide Map</h3>
									<div>
										Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className='card-profile shadow'>
					<Card>
						<CardBody>
							<div style={{'height':'100px'}} className='text-center mb-3' >
								<img src={Image1} alt='Image1'  className='thumbnail img-fluid'/>
							</div>
							<div className='text-center' >
								<h3 className='text-dark'>Itogon Landslide Map</h3>
								<div>
									Itogon Rainfall-triggered Landslide Susceptibility Map located in 12.604N 121.323E 
								</div>
							</div>
						</CardBody>
					</Card>
				</Col> */}
			{/* </Row> */}
		</section>
		<section>
			
		</section>
      </Fragment>
  )
}

export default ProductsServices;
