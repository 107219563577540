import React from 'react'
import gql from 'graphql-tag';

export const MD = gql`
query RainRate($Site: String!) {
    queryMountainDews2SBySitebyDate(Site: $Site, first: 1) {
        items {
            BatteryLevel
            Date
            Humidity
            KeyId
            Pressure
            RainRate
            Site
            WindSpeed
            Temperature
            SoilMoisture1
            SoilMoisture2
            SoilMoisture3
            SoilEC1
            SoilEC2
            SoilEC3
            SoilTemperature1
            SoilTemperature2
            SoilTemperature3
        }
    }
}
`;