import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components
import AuthNavbar from '../components/Navbars/AuthNavbar.js';
import AuthFooter from '../components/Footers/AuthFooter.js';
import Login from '../views/Guest/Login';

import '../assets/css/argon-dashboard-react.min.css';
import '../assets/css/argon-dashboard-react.css.map';
const Auth = (props) => (
	<Fragment>
		<div className='main-content'>
			<AuthNavbar />
			<div className='header bg-gradient-info py-6 py-lg-7'>
				<Container>
					<div className='header-body text-center mb-7'>
						<Row className='justify-content-center'>
							<Col lg='5' md='6'>
								<h1 className='text-white'>Welcome!</h1>
								<p className='text-lead text-light'>
									Project of Scarp and Developed for your needs.
								</p>
							</Col>
						</Row>
					</div>
				</Container>
				<div className='separator separator-bottom separator-skew zindex-100'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						preserveAspectRatio='none'
						version='1.1'
						viewBox='0 0 2560 100'
						x='0'
						y='0'>
						<polygon className='fill-default' points='2560 0 2560 100 0 100' />
					</svg>
				</div>
			</div>
			{/* Page content */}
			<Container className='mt--8 pb-5'>
				<Row className='justify-content-center'>
					<Switch>
						<Route path={props.match.url + '/login'} render={Login} />
						<Redirect from='*' to='/auth/login' />
					</Switch>
				</Row>
			</Container>
		</div>
		<AuthFooter />
	</Fragment>
);

export default Auth;
