import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { Line } from 'react-chartjs-2';

// import LineChart from './LineChart';

const sensor = gql`
  query MyQuery {
    queryMountainDews2SBySitebyDate(Site: "MD1", first: 192) {
      items {
        SoilMoisture1
        SoilMoisture2
        SoilMoisture3
        Date
      }
    }
  }
`;

const SoilTab = ({ todos: obj }) => {
  const values = Object.keys(obj).reduce(
    (agg, curr) => {
      let newAgg = agg;
      newAgg.date.push(obj[curr].Date);
      newAgg.sensor.push(obj[curr].SoilMoisture1);
      newAgg.sensor2.push(obj[curr].SoilMoisture2);
      newAgg.sensor3.push(obj[curr].SoilMoisture3);


      return newAgg;
    },
    { date: [], sensor: [], sensor2: [], sensor3: [] }
  );


  const LineChart = ({ labels, values, label, bgColor, borderColor, borderWidth,  pointRadius }) => {
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Soil Moisture1',
          data: values.sensor,
          fill: false,
          bgColor:'rgba(15, 255, 51, 0.9)',
          borderColor: 'rgb(8, 83, 46, 1)',
          borderWidth: '1',
          pointRadius: '1',
        },
        {
          label: 'Soil Moisture2',
          data: values.sensor2,
          fill: false,
          bgColor:'rgba(15, 255, 51, 0.9)',
          borderColor: 'rgba(206, 35, 45, 1)',
          borderWidth: '1',
          pointRadius: '1',
        },
        {
          label: 'Soil Moisture3',
          data: values.sensor3,
          fill: false,
          bgColor:'rgba(15, 255, 51, 0.9)',
          borderColor: 'rgba(255, 209, 16, 1)',
          borderWidth: '1',
          pointRadius: '1',
        }
      ]
    };
  
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: false
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              position: 'left',
              reverse: true
            }
          }
        ]
      }
    };
  
    return <Line data={data} options={options} />;
  };
  



  return (
    <LineChart
      labels={values.date}
      values={values}
    />
  );
};

export default compose(
  graphql(sensor, {
    options: {
      fetchPolicy: 'cache-and-network'
    },
    props: (props) => ({
      todos: props.data.queryMountainDews2SBySitebyDate
        ? props.data.queryMountainDews2SBySitebyDate.items
        : []
    })
  })
)(SoilTab);
