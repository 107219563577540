import React, { useState, useEffect } from 'react';
import { ImageOverlay, MapContainer, TileLayer, ZoomControl, LayersControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import axios from 'Axios.js';

import PNGfiles from '../../variables/PNGfiles'; // still image - fix this
import Cloud from '../../variables/cloud.json';
import FormControl1 from '../../components/Markers/FormControl1';
import ItogonBounds from '../../components/Markers/ItogonBounds';
import GetCoord from '../../components/OpenweatherAPI/GetCoord';
import MarkerSensor from '../../components/Markers/Sensors';

const clickableBounds = [
  [5.465192, 116.944479],
  [18.830924, 126.768633]
];

const GIFBounds = [
  [4.405, 115.8],
  [22.1, 129.5]
];

const mapAttribution =
  'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';
const mapUrl =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';

const Map = ({ notif,setLocation}) => {
  const [site, setSite] = useState([]);
  const [sitenum, setSitenum] = useState([])


  useEffect(() => {
    axios
      .get('/site-03282022.json')
      .then((response) => {
        var site_list = [];
        Object.entries(response.data.site).map(([s_k, s_v]) => {
          // console.log(s_v)
          site_list.push(<MarkerSensor Site={s_v.Site} key={s_k} point={[s_v.Longitude, s_v.Latitude]} Name={s_v.Name} Image={s_v.Image} />);
          return 0;
        });
        setSite(site_list);
      })
      .catch((error) => notif(error.message, 'danger'));
  }, [notif]);

  return (
    <MapContainer
      bounds={clickableBounds}
      zoomControl={false}
      scrollWheelZoom={true}
      style={{ height: 'calc(90vh - 117px)' }}
      className='position-relative z-index-n1'>
      <LayersControl position='topright'>
        <PNGfiles />

        {/* <div>
          <ImageOverlay url={Cloud.url} bounds={GIFBounds}></ImageOverlay>
        </div> */}

        <TileLayer attribution={mapAttribution} url={mapUrl} />
        <ZoomControl position='topleft' />

        <MarkerClusterGroup>{site}</MarkerClusterGroup>
        <FormControl1 position='topleft' />
      </LayersControl>

      <ItogonBounds />

      <GetCoord setLocation={setLocation} />
    </MapContainer>
  );
};

export default Map;
