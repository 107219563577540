import React from 'react';
import { Line } from 'react-chartjs-2';

//aws AppSync
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';

const sensor = gql`
	query MyQuery {
		queryMountainDews2SBySitebyDate(Site: "MD1", first: 10) {
			items {
				BatteryLevel
				Date
				RainRate
			
			}
		}
	}
`;

const rainChart = (props) => {
	var obj = props.todos;

	var date = Object.entries(obj).map(([k, v], i) => {
		return [obj[k].Date];
	});

	var rainRate = Object.entries(obj).map(([k, v], i) => {
		return obj[k].RainRate;
	});

	const data1 = {
		labels: date,
		datasets: [
			{
				label: 'Rain rate',
				data: [...rainRate],
				fill: false,
				backgroundColor: 'rgb(255, 99, 132)',
				borderColor: 'rgba(255, 99, 132, 0.2)'
			}
		]
	};

	const options = {
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true
					}
				}
			],
			xAxes: [
				{
					ticks: {
						beginAtZero: true,
						position: 'left',
						reverse: true
					}
				}
			]
		}
	};

	return (
		<div>
			<Line data={data1} options={options} />
		</div>
	);
};

export default compose(
	graphql(sensor, {
		options: {
			fetchPolicy: 'cache-and-network'
		},
		props: (props) => ({
			todos: props.data.queryMountainDews2SBySitebyDate
				? props.data.queryMountainDews2SBySitebyDate.items
				: []
		})
	})
)(rainChart);
