import React, { Fragment } from 'react';
import 'leaflet/dist/leaflet.css';
import './ContactUs.css'

// reactstrap components
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import icon from '../../assets/img/brand/scarplogo.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


let DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow,
	iconSize: [60, 50],
	shadowSize: [30, 50],
	iconAnchor: [15, 30],
	shadowAnchor: [0, 50],
	popupAnchor: [0, 100]
});
L.Marker.prototype.options.icon = DefaultIcon;

const ContactUs = (props) => {

	return(
	<Fragment>
		<section className='justify-content-center'>
			<Row>
			<Col className='align-items-center text-center'>
					<Container>
						<h1 className='page-header overflow-hidden mob'>CONTACT US</h1>
						
					</Container>
				</Col>
			</Row>
		</section>
		<section className='justify-content-center bg-white py-20vh overflow-hidden'>
			<Container>
				<Row className=' pb-5rem'>
					<Col className='mb-5' data-aos='fade-up' data-aos-delay='100'>
						<Card className='border-0 border-top border-top-lg border-primary shadow h-100 hover-up text-center'>
							<CardBody>
								<div class='icon-stack icon-stack-xl bg-primary-soft text-primary mb-4 mt-n5 z-1 shadow'>
									<i class='fas fa-user'></i>
								</div>
								<h5>Developer</h5>
								<p class='card-text'>
									Want to know how it works? Want to be part of the team?
								</p>
							</CardBody>
							<CardFooter>
								<div class='text-primary font-weight-bold d-inline-flex align-items-center'>
									Contact Developer
									<i class='fas fa-arrow-right text-xs ml-1'></i>
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col className='mb-5' data-aos='fade-up' data-aos-delay='200'>
						<Card className='border-0 border-top border-top-lg border-warning shadow h-100 hover-up text-center'>
							<CardBody>
								<div class='icon-stack icon-stack-xl bg-warning-soft text-warning mb-4 mt-n5 z-1 shadow'>
									<i class='fas fa-headset'></i>
								</div>
								<h5>Support</h5>
								<p class='card-text'>
									Need help with a product that you just purchased? Need help
									with your account?
								</p>
							</CardBody>
							<CardFooter>
								<div class='text-warning font-weight-bold d-inline-flex align-items-center'>
									Contact Support
									<i class='fas fa-arrow-right text-xs ml-1'></i>
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col className='mb-5' data-aos='fade-up' data-aos-delay='300'>
						<Card className='border-0 border-top border-top-lg border-teal shadow h-100 hover-up text-center'>
							<CardBody>
								<div class='icon-stack icon-stack-xl bg-teal-soft text-teal mb-4 mt-n5 z-1 shadow'>
									<i class='fas fa-camera-retro'></i>
								</div>
								<h5>Media</h5>
								<p class='card-text'>
									Looking to contact our media team for a press release or
									related story?
								</p>
							</CardBody>
							<CardFooter>
								<div class='text-teal font-weight-bold d-inline-flex align-items-center'>
									Contact Media
									<i class='fas fa-arrow-right text-xs ml-1'></i>
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				<Row className='justify-content-center text-center'>
					<Col
						className='col-lg-5 mb-5 mb-lg-0'
						data-aos='fade-up'
						data-aos-delay='100'>
						<h5>Join us on Facebook!</h5>
						<p class='font-weight-light mb-0'>
							Join the discussion on Facebook. Our community can help answer
							questions!
						</p>
					</Col>
					<Col className='col-lg-5' data-aos='fade-up' data-aos-delay='200'>
						<h5>General Support</h5>
						<p class='font-weight-light mb-0'>
							For any other support questions, please send us an email at
							<a href='#!'> support@scarp.com</a>
						</p>
					</Col>
				</Row>
			</Container>
		</section>
		<section className='justify-content-center bg-white py-20vh overflow-hidden'>
			<Row className=' px-5'>
			<Card className='border-0 border-top border-top-lg border-primary shadow h-100 hover-up text-center'>
				<CardBody>
					<div class='icon-stack icon-stack-xl bg-primary-soft text-primary mb-4 mt-n5 z-1 shadow'>
						<i class='fas fa-user'></i>
					</div>
					<h5>Engr. Fibor J. Tan</h5>
					<p>Project Leader</p>
					<p>fjtan@mapua.edu.ph</p>
					<p class='card-text'>
						RLab4 Yuchengco Innovation Center
					</p>
					<p class='card-text'>
						Mapua University, Intramuros, Manila 1002
					</p>
				</CardBody>
				<CardFooter>
					<div class='text-primary font-weight-bold d-inline-flex align-items-center'>
						Contact Project Leader
						<i class='fas fa-arrow-right text-xs ml-1'></i>
					</div>
				</CardFooter>
			</Card>
			<Col className='px-5' >
			<MapContainer  center={[14.590713, 120.978564]} zoom={18} zoomControl={true} scrollWheelZoom={true} style={{ height: '50vh' }}>
				<TileLayer				
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<Marker position={[14.590713, 120.978564]}/>
			</MapContainer>
			</Col>
			</Row>
		</section>
	</Fragment>
	)
};

export default ContactUs;
