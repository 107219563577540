import React, { Fragment } from 'react';

// reactstrap components
import { Col, Container, Row } from 'reactstrap';

import ScarpLogo from '../../assets/img/brand/scarplogo.png';
import classesAboutUs from './AboutUs.module.css'

const AboutLogo = (props) => (
	<Fragment>
		<section className='justify-content-center bg-gradient-info'>
			<Row>
				<Col className='align-items-center text-center'>
					<Container>
						<h1 className='page-header'>About the SCaRP Logo</h1>
						<div class='svg-border-rounded text-white'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 144.54 17.34'
								preserveAspectRatio='none'
								fill='currentColor'>
								<path d='M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0'></path>
							</svg>
						</div>
					</Container>
				</Col>
			</Row>
		</section>
		<section className='justify-content-center bg-white py-20vh'>
			<Container>
				<Row>
					<Col data-aos='fade-right'>
					<img
						src={ScarpLogo}
						className={classesAboutUs.scarpImg}
						style={{ height: '50vh' }}
						alt='SCaRP motivation'
						 />
					</Col>
					<Col data-aos='fade-left'>
						<p>
                        The SCaRP logo conveys the rainfall-induced landslide as it's objective. The logo starts at it's acronym S for the simulating followed by C that stands for Cascading and depicted as an scarp and the placement of A that conveys as the pre-landslide which is an important symbol to quantify the sediment delivery by computing the difference between the pre and post landslide events. The A also may serve as the placement of slope protection for the identified location of high risk rainfall-induced landslide that is one of the main objectives of SCaRP project. The R stands for the Rainfall-Induced Landslide and P is the Philippines as the project sites are located within the entire Philippines. Hence, SCaRP logo wholly describe the Simulating and Cascading of Rainfall-induced Landslide of the Philippines Project.
						</p>
						
					</Col>
				</Row>
			</Container>
		</section>
	</Fragment>
);

export default AboutLogo;
