import React, { Fragment, useState } from 'react';
// import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';


import classes from './SidebarPublic.module.css';

import routes_public from '../../views/Guest/routes_public'
import LSjson from '../../variables/LSjson';
// import '@trendmicro/react-sidenav/dist/react-sidenav.css';


function SidebarPublic(props) {

    const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const closeCollapse = () => setIsOpen(false);


    return (
       
               
                <nav className='nav flex-column'>
                    <ul >
                        <button >
                        button
                        </button>
                        {routes_public.map((item, index) => {
                        return (
                            <li key={index} >
                            <Link to={item.path}>
                                {item.icon}
                                <div ideba>{item.title}</div>
                            </Link>
                            </li>
                        );
                        })}
                    </ul>
                </nav>
                // <div className={classes.navbar}>
                // <Link to='#' className={classes.menubars}>
                //     <i className="fas fa-arrow-right"  onClick={closeCollapse} />
                // </Link>
                // </div>

        
    //     <Navbar
    //     className={classes.sidebar}
    //     expand='md'
    //     id='sidenav'>
    //     <Container fluid>
    //         {/* Toggler */}
    //         <NavbarToggler onClick={toggle} />
    //         {/* Brand */}
    //         <NavbarBrand className='pt-0'>
    //             logo
    //         </NavbarBrand>
    //         {/* User */}
    //         <Nav className='align-items-center d-md-none'>
    //             <UncontrolledDropdown nav>
    //                 <DropdownToggle nav className='nav-link-icon'>
    //                     <i className='ni ni-bell-55' />
    //                 </DropdownToggle>
    //                 <DropdownMenu
    //                     aria-labelledby='navbar-default_dropdown_1'
    //                     className='dropdown-menu-arrow'
    //                     right>
    //                     <DropdownItem>Action</DropdownItem>
    //                     <DropdownItem>Another action</DropdownItem>
    //                     <DropdownItem divider />
    //                     <DropdownItem>Something else here</DropdownItem>
    //                 </DropdownMenu>
    //             </UncontrolledDropdown>
    //             <UncontrolledDropdown nav>
    //                 <DropdownToggle nav>
    //                     <Media className='align-items-center'>
    //                         <span className='avatar avatar-sm rounded-circle'>
    //                         <i className='fas fa-user' />
    //                             {/* <img
    //                                 alt='...'
    //                                 src={require('../../assets/img/theme/team-1-800x800.jpg')}
    //                             /> */}
    //                         </span>
    //                     </Media>
    //                 </DropdownToggle>
    //                 <DropdownMenu className='dropdown-menu-arrow' right>
    //                     <DropdownItem className='noti-title' header tag='div'>
    //                         <h6 className='text-overflow m-0'>Welcome!</h6>
    //                     </DropdownItem>
    //                     <DropdownItem to='/admin/user-profile' tag={Link}>
    //                         <i className='ni ni-single-02' />
    //                         <span>My profile</span>
    //                     </DropdownItem>
    //                     <DropdownItem to='/admin/user-profile' tag={Link}>
    //                         <i className='ni ni-settings-gear-65' />
    //                         <span>Settings</span>
    //                     </DropdownItem>
    //                     <DropdownItem to='/admin/user-profile' tag={Link}>
    //                         <i className='ni ni-calendar-grid-58' />
    //                         <span>Activity</span>
    //                     </DropdownItem>
    //                     <DropdownItem to='/admin/user-profile' tag={Link}>
    //                         <i className='ni ni-support-16' />
    //                         <span>Support</span>
    //                     </DropdownItem>
    //                     <DropdownItem divider />
    //                     <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
    //                         <i className='ni ni-user-run' />
    //                         <span>Logout</span>
    //                     </DropdownItem>
    //                 </DropdownMenu>
    //             </UncontrolledDropdown>
    //         </Nav>
    //         {/* Collapse */}
    //         <Collapse navbar isOpen={isOpen}>
    //             {/* Collapse header */}
    //             <div className='navbar-collapse-header d-md-none'>
    //                 <Row>
    //                     <Col className='collapse-brand' xs='6'>
    //                         <Link to='/'>
    //                            logo
    //                         </Link>
    //                     </Col>
    //                     <Col className='collapse-close' xs='6'>
    //                         <button
    //                             className='navbar-toggler'
    //                             type='button'
    //                             onClick={toggle}>
    //                             <span />
    //                             <span />
    //                         </button>
    //                     </Col>
    //                 </Row>
    //             </div>
    //             {/* Form */}
    //             <Form className='mt-4 mb-3 d-md-none'>
    //                 <InputGroup className='input-group-rounded input-group-merge'>
    //                     <Input
    //                         aria-label='Search'
    //                         className='form-control-rounded form-control-prepended'
    //                         placeholder='Search'
    //                         type='search'
    //                     />
    //                     <InputGroupAddon addonType='prepend'>
    //                         <InputGroupText>
    //                             <span className='fa fa-search' />
    //                         </InputGroupText>
    //                     </InputGroupAddon>
    //                 </InputGroup>
    //             </Form>
    //             {/* Navigation */}
    //             <Nav navbar>routes</Nav>
    //             {/* Divider */}
    //             <hr className='my-3' />
    //             {/* Heading */}
    //             <h6 className='navbar-heading text-muted'>Documentation</h6>
    //             {/* Navigation */}
    //             <Nav className='mb-md-3' navbar>
    //                 <NavItem>
    //                     <NavLink href='https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar'>
    //                         <i className='ni ni-spaceship' />
    //                         Getting started
    //                     </NavLink>
    //                 </NavItem>
    //                 <NavItem>
    //                     <NavLink href='https://demos.creative-tim.com/argon-dashboard-react/#/documentation/colors?ref=adr-admin-sidebar'>
    //                         <i className='ni ni-palette' />
    //                         Foundation
    //                     </NavLink>
    //                 </NavItem>
    //                 <NavItem>
    //                     <NavLink href='https://demos.creative-tim.com/argon-dashboard-react/#/documentation/alerts?ref=adr-admin-sidebar'>
    //                         <i className='ni ni-ui-04' />
    //                         Components
    //                     </NavLink>
    //                 </NavItem>
    //             </Nav>
    //         </Collapse>
    //     </Container>
    // </Navbar>

        // <SideNav
        //     onSelect={(selected) => {
        //         // Add your code here
        //     }}
        //     className={classes.sidebar}
        // >
        //     <SideNav.Toggle />
           
        //     <SideNav.Nav >
        //         <NavItem>
        //             <NavText > {getLss(props.routes)} </NavText>
        //         </NavItem>
                /* <NavItem eventKey="LS" >
                    <NavIcon>
                        <i className="fa fa-industry" style={{ fontSize: '1.5em' }} />
                    </NavIcon>
                    <NavText className={classes.navtextItem}>
                        Sediment Deposition
                    </NavText>
                    <NavItem eventKey="LS/verylow" >
                        <NavText>
                        <i className="fas fa-square-full " style={{ color: '#be1717' }}></i>
                           <span className='ml-auto'> Very High</span>
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="LS/low">
                    <NavText>
                        <i className="fas fa-square-full " style={{ color: '#4dbe17' }}></i>
                           <span className='ml-auto'> High</span>
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="LS/moderate">
                    <NavText>
                        <i className="fas fa-square-full " style={{ color: '#fbf930' }}></i>
                           <span className='ml-auto'> Moderate</span>
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="LS/high">
                    <NavText>
                        <i className="fas fa-square-full" style={{ color: '#800080' }}></i>
                           <span className='ml-auto'> Low</span>
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="LS/veryhigh">
                    <NavText>
                        <i className="fas fa-square-full" style={{ color: '#ff99ff' }}></i>
                           <span className='ml-auto'> Very Low</span>
                        </NavText>
                    </NavItem>
                </NavItem>
                <NavItem eventKey="c2">
                    <NavIcon>
                        <i className="fa fa-eraser" style={{ fontSize: '1.5em' }} />
                    </NavIcon>
                    <NavText className={classes.navtextItem}>
                        Sediment Deposition
                    </NavText>
                    <NavItem eventKey="c2/low">
                    <NavText>
                        <i className="fas fa-square-full " style={{ color: '#4dbe17' }}></i>
                           <span className='ml-auto'> High</span>
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="c2/moderate">
                    <NavText>
                        <i className="fas fa-square-full " style={{ color: '#fbf930' }}></i>
                           <span className='ml-auto'> Moderate</span>
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="c2/high">
                    <NavText>
                        <i className="fas fa-square-full" style={{ color: '#800080' }}></i>
                           <span className='ml-auto'> Low</span>
                        </NavText>
                    </NavItem>
                </NavItem> */
               
        //     </SideNav.Nav>
        // </SideNav>
    )
}

export default SidebarPublic
