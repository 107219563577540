import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
  } from 'reactstrap';

//aws AppSync
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import classes from './ImageSensor.module.css'


const sensor = gql `
query MyQuery {
  queryMountainDews2SBySitebyDate(Site: "1S", first: 1) {
    items {
      BatteryLevel
      Date
      KeyId

    }
  }
}
`;




const ImageSensor = (props) => {
  // console.log(props.todos)

  var obj = props.todos

  var date = Object.entries(obj).map(([k, v], i) => {
    return(
     ([obj[k].Date])
    )
  })


  return (
    <div>
        <Card className="shadow">
        <CardHeader className="bg-transparent">
            <Row className="align-items-center">
            
            <div className="col">
                <h6 className="text-uppercase text-muted ls-1 mb-1">
                Image Capture
                </h6>
                <h2 className="mb-0">as of  {date}</h2>
            </div>

            </Row>
        </CardHeader>
        <CardBody>
            <img 
            src = 'https://imgsensor.s3.ap-northeast-1.amazonaws.com/latestimagebase1?fbclid=IwAR0gU2WE4_zrOS0bV2nDqsNxj4SIOwbxMMSUxFYukJj8KYxrrETXhlAPl6A'
            alt= 'Itogon' 
            className={classes.ImageContainer}/>
        </CardBody>
        </Card>
    </div>
  )


    
};


export default compose (
    graphql(sensor, {
      options: {
        fetchPolicy: 'cache-and-network'
      },
      props: props => ({
        todos: props.data.queryMountainDews2SBySitebyDate ? props.data.queryMountainDews2SBySitebyDate.items : []
      })
    })
  )(ImageSensor);
  