
import React from 'react';


const SidebarData = [
  {
    title: 'Landslide Susceptibility',
    path: '/',
    loc: [[16.318, 120.584],[16.472, 120.716]],
    icon: <i className='ni ni-calendar-grid-58' />,
    url: 'https://lsvector1.s3.ap-southeast-1.amazonaws.com/itogon_png2.png',
  }
  // {
  //   title: 'Landslide Susceptibility',
  //   path: '/',
  //   icon: <i className='ni ni-calendar-grid-58' />,
  //   url: 'https://lsvector1.s3.ap-southeast-1.amazonaws.com/LSS_whole.json',
  // },
  // {
  //   title: 'Itogon',
  //   path: '/',
  //   icon: <i className='ni ni-calendar-grid-58' />,
	//   url: 'https://lsvector1.s3.ap-southeast-1.amazonaws.com/Itogon.json',
  // },
  // {
  //   title: 'La Trinidad',
  //   path: '/reports',
  //   icon: <i className='ni ni-calendar-grid-58' />,
	//   url: 'https://lsvector1.s3.ap-southeast-1.amazonaws.com/LaTrinidad.json',
  // },
  // {
  //   title: 'Baguio City',
  //   path: '/',
  //   icon: <i className='ni ni-calendar-grid-58' />,
	//   url: 'https://lsvector1.s3.ap-southeast-1.amazonaws.com/BaguioCity_json.json',
  // },
  // {
  //   title: 'Tuba',
  //   path: '/',
  //   icon: <i className='ni ni-calendar-grid-58' />,
	//   url: 'https://lsvector1.s3.ap-southeast-1.amazonaws.com/Tuba.json',
  // }
 
];



export default SidebarData;
