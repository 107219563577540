import React, { useState, useEffect } from 'react';
import { useOpenWeather } from 'react-open-weather';
import { Row, Col } from 'reactstrap';
import axios from 'axios';

import './openweather.css';

const api = {
  key: 'e0249fde153c7c9024642b0fce10868f',
  base: 'https://api.openweathermap.org/data/2.5/'
};

function Openweather({ location }) {
  const [current, setCurrent] = useState({ temperature: { current: 0 } });
  const [city, setCity] = useState('');
  const { data, isLoading } = useOpenWeather({
    key: api.key,
    lat: location.lat,
    lon: location.lng,
    lang: 'en',
    unit: 'metric'
  });

  axios
    .get(
      `https://api.openweathermap.org/geo/1.0/reverse?lat=${location.lat}&lon=${location.lng}&limit=5&appid=${api.key}`
    )
    .then(({ data: geoData }) => {
      if (geoData.length > 0) {
        setCity(geoData[0].state);
      }
    });

  useEffect(() => {
    if (data && !isLoading) {
      setCurrent(data.current);
    }
  }, [data, isLoading]);

  return (
    <Row>
      <Col lg='8' className='order-last order-lg-first'>
        <Row className='d-none d-lg-block'>
          <Col>
            <h1 className='mb-0'>{city}</h1>
          </Col>
        </Row>
        <Row className='d-none d-lg-block'>
          <Col>{current.date}</Col>
        </Row>
        <Row className='text-center'>
          <Col xs='4'>
            <h5>Temperature</h5>
            <h3>{current.temperature.current} C°</h3>
          </Col>
          <Col xs='4'>
            <h5>Humidity</h5>
            <h3>{current.humidity} %</h3>
          </Col>
          <Col xs='4'>
            <h5>Wind</h5>
            <h3>{current.wind} Km/hr</h3>
          </Col>
        </Row>
      </Col>
      <Col lg='4' className='text-center'>
        <Row className='d-block d-lg-none'>
          <Col>
            <h1 className='mb-0 mt-4'>{city}</h1>
          </Col>
        </Row>
        <Row className='d-block d-lg-none mb-4'>
          <Col>{current.date}</Col>
        </Row>
        <Row>
          <Col>
            <svg
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              width='100'
              height='100'
              viewBox='0 -5 35 40'>
              <path d={current.icon} />
            </svg>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col className='text-capitalize'>{current.description}</Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Openweather;
