import React from 'react';
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

import scarpLogo from 'assets/img/brand/scarplogo.png';
import DOST from 'assets/img/brand/dost.png';
import PCIEERD from 'assets/img/brand/PCIEERD.png';
import NERC from 'assets/img/brand/NERC.jpg';
import Newton from 'assets/img/brand/Newton.png';
import Mapua from 'assets/img/brand/mapua.png';
import Exeter from 'assets/img/brand/exeter.png';
import UEA from 'assets/img/brand/UEA.png';
import PAGASA from 'assets/img/brand/PAGASA.png';
import MGB from 'assets/img/brand/MGB.png';
import Philvocs from 'assets/img/brand/Philvocs.png';
import DPWH from 'assets/img/brand/DPWH.png';
import UPD from 'assets/img/brand/UPD.png';
import Glasgow from 'assets/img/brand/UGlasgow.png';
import NAMRIA from 'assets/img/brand/namria.png';
import BSWM from 'assets/img/brand/BSWM.png';
import NWRB from 'assets/img/brand/NWRB.png';
import TheWeatherPhil from 'assets/img/brand/TheWeatherPhil.png';

import LinkHolder from './LinkHolder';
import classes from './LandingFooter.module.css';

const LandingFooter = () => {
  const usefulLinks = [
    { link: './home', label: 'Home' },
    { link: './aboutus', label: 'About Us' },
    { link: './portfolio', label: 'Portfolio' },
    { link: './contactus', label: 'Contact Us' }
  ];

  const projects = [
    { link: './projects/c2', label: 'C2 Component' },
    { link: './projects/royal', label: 'Royal Component' },
    { link: './projects/mtdews', label: 'MtDews Component' }
  ];

  const implementing = [{ link: 'https://www.mapua.edu.ph/', label: 'Mapúa University' }];

  const ukCounterPart = [
    { link: '#', label: 'University of Exeter' },
    { link: '#', label: 'University of East Anglia' }
  ];

  const fundingAgency = [
    { link: '#', label: 'DOST' },
    { link: '#', label: 'DOST - PCIEERD' },
    { link: '#', label: 'Newton Agham' },
    { link: '#', label: 'NERC Science of Environment' }
  ];

  const collaboratingAgencies = [
    { link: '#', label: 'DOST - PAGASA' },
    { link: '#', label: 'MGB' },
    { link: '#', label: 'DOST - Philvocs' },
    { link: '#', label: 'DPWH' }
  ];

  const supportingAgencies = [
    { link: '#', label: 'UP Diliman' },
    { link: '#', label: 'University of Glasgow' },
    { link: '#', label: 'NAMRIA' },
    { link: '#', label: 'DA - BSWM' },
    { link: '#', label: 'NWRB' },
    { link: '#', label: 'The Weather Philippines' }
  ];

  return (
    <footer>
      <Row className='m-0 py-4 bg-light'>
        <Col lg='2' className='text-center text-lg-left'>
          <Row>
            <Col className='text-center'>
              <img alt='SCaRP Logo' src={scarpLogo} className='img-fluid' style={{ maxHeight: '100px' }} />
            </Col>
          </Row>
          <h3>Mapúa SCaRP Project</h3>
          <p className='small'>
            RRm1, Yuchengco Innovation Center, <br />
            Mapúa University, 658 Muralla St., <br />
            Intramuros, Manila 1002, Philippines <br />
            <br />
            <strong>Phone:</strong> +63(2) 8247-5000 <br />
            <strong>Email:</strong> scarpmtdews@gmail.com
          </p>
        </Col>
        <Col lg='2'>
          <Row>
            <Col lg='12' sm='6'>
              <h3>Useful Links</h3>
              <LinkHolder links={usefulLinks} />
            </Col>
            <Col lg='12' sm='6'>
              <h3 className='mt-lg-4'>Projects</h3>
              <LinkHolder links={projects} />
            </Col>
          </Row>
        </Col>
        <Col lg='6 pt-4 pt-lg-0'>
          <h3 className='text-center'>Agencies</h3>
          <Row>
            <Col sm='6' lg='4'>
              <Row>
                <Col md='6' lg='12'>
                  <h5 className='mb-0'>IMPLEMENTING AGENCY</h5>
                  <LinkHolder links={implementing} />
                  <h5 className='mt-3 mb-0'>UK COUNTEPARTS</h5>
                  <LinkHolder links={ukCounterPart} />
                </Col>
                <Col md='6' lg='12'>
                  <h5 className='mt-sm-3 mt-md-0 mt-lg-3 mb-0'>FUNDING AGENCIES</h5>
                  <LinkHolder links={fundingAgency} />
                </Col>
              </Row>
            </Col>
            <Col sm='6' lg='8'>
              <Row>
                <Col sm='12' md='6'>
                  <h5 className='mb-0'>COLLABORATING AGENCIES</h5>
                  <LinkHolder links={collaboratingAgencies} />
                </Col>
                <Col sm='12' md='6'>
                  <h5 className='mt-sm-3 mt-md-0 mb-0'>SUPPORTING AGENCIES</h5>
                  <LinkHolder links={supportingAgencies} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg='2' className='pt-sm-4 p-0 text-center text-lg-left'>
          <img alt='Mapúa University' src={Mapua} className={`img-fluid ${classes.partner}`} />
          <img alt='Exeter' src={Exeter} className={`img-fluid ${classes.partner}`} />
          <img alt='DOST' src={DOST} className={`img-fluid ${classes.partner}`} />
          <img alt='PCIEERD' src={PCIEERD} className={`img-fluid ${classes.partner}`} />
          <img alt='NERC' src={NERC} className={`img-fluid ${classes.partner}`} />
          <img alt='Newton' src={Newton} className={`img-fluid ${classes.partner}`} />

          {/* Replace This */}
          <img alt='UEA' src={UEA} className={`img-fluid ${classes.partner}`} />
          <img alt='PAGASA' src={PAGASA} className={`img-fluid ${classes.partner}`} />
          <img alt='MGB' src={MGB} className={`img-fluid ${classes.partner}`} />
          <img alt='Philvocs' src={Philvocs} className={`img-fluid ${classes.partner}`} />
          <img alt='DPWH' src={DPWH} className={`img-fluid ${classes.partner}`} />
          <img alt='UPD' src={UPD} className={`img-fluid ${classes.partner}`} />
          <img alt='Glasgow' src={Glasgow} className={`img-fluid ${classes.partner}`} />
          <img alt='NAMRIA' src={NAMRIA} className={`img-fluid ${classes.partner}`} />
          <img alt='DA-BSWM' src={BSWM} className={`img-fluid ${classes.partner}`} />
          <img alt='NWRB' src={NWRB} className={`img-fluid ${classes.partner}`} />
          <img alt='WeatherPhil' src={TheWeatherPhil} className={`img-fluid ${classes.partner}`} />
        </Col>
      </Row>
      <Row className={classes.bottomRow}>
        <Col>© 2020 SCaRP - Mt.Dews</Col>
      </Row>
    </footer>
  );
};

export default LandingFooter;
