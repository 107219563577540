import React, { Fragment, useState, useEffect } from 'react';
// reactstrap components
import { Col, Row, Nav, ListGroup, ListGroupItem, Button, Input, Label, UncontrolledCollapse, Navbar, Modal, Collapse, NavbarToggler } from 'reactstrap';
// Components
import axios from '../../Axios';
import MarkerBaseStation from '../../components/Markers/BaseStation';
import MarkerSensor from '../../components/Markers/Sensors';
import AlertPop from '../../components/AlertPop/AlertPop';
import Cloud from '../../variables/cloud.json';
import LSjson from '../../variables/LSjson';
import SidebarPublic from '../../components/Sidebar/SidebarPublic';
import routes from './routes_public'

// Leaflet
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { ImageOverlay, MapContainer, TileLayer, ZoomControl, LayersControl, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './map.module.css';
import classes from './map.module.css';
import { objectLessAttributes } from '@aws-amplify/core';
let DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow,
	iconSize: [30, 30],
	shadowSize: [30, 50],
	iconAnchor: [15, 30],
	shadowAnchor: [0, 50],
	popupAnchor: [0, 100]
});
L.Marker.prototype.options.icon = DefaultIcon;

const Map = (props) => {
	// Markers
	const [baseStation, setBaseStation] = useState([]);
	const [site, setSite] = useState([]);



	// Alert
	const [notifVisible, setNotifVisible] = useState(false);
	const [notifColor, setNotifColor] = useState('success');
	const [notifMessage, setNotifMessage] = useState('');

	const handleNotif = (color, message) => {
		setNotifVisible(true);
		setNotifColor(color);
		setNotifMessage(message);
	};
	const handleNotifToggle = () => {
		setNotifVisible((state) => !state);
	};

	
	const GIFBounds = [
		[4.405, 115.8],
		[22.1, 129.5]
	];


	useEffect(() => {
		axios
			.get('/site-03162022.json')
			.then((response) => {
				var base_list = [];
				var site_list = [];
				var GifRef = [];
				Object.entries(response.data.basestation).map(([b_k, b_v]) => {
					GifRef.push(<ImageOverlay key={'io' + b_k} />);
					base_list.push(
						<MarkerBaseStation
							key={b_k}
							name={b_v.Name}
							address={b_v.Address}
							point={[b_v.Latitude, b_v.Longitude]}
						/>
					);
					if (b_v.site) {
						Object.entries(b_v.site).map(([s_k, s_v]) => {
							site_list.push(
								<MarkerSensor key={s_k} point={[s_v.Longitude, s_v.Latitude]} name={s_v.Name} site={s_v.Site} />
							);
							return 0;
						});
					}
					return 0;
				});

				setBaseStation(base_list);
				setSite(site_list);
			})
			.catch((error) => handleNotif('danger', error.message));
	}, [notifVisible]);





	return (
		<Fragment>
			<section>
				<Row className='m-0'>
					
					<Col className='container mw-100'>
					
						<MapContainer  center={[16.35, 120.7]} zoom={12} zoomControl={false} scrollWheelZoom={false} style={{ height: '90vh' }}>
						<LayersControl position="topright">
							
								{/* <GeoJSON data={Tuba} style={styleGeoJSON} /> */}
								<LSjson />
						
							
							{/* <div>
								<ImageOverlay url={Cloud.url} bounds={GIFBounds}></ImageOverlay>
							</div> */}
							
							<TileLayer
							
								attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
								url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
							/>
							<ZoomControl position='topright' />
							
							{baseStation}
							{site}
						</LayersControl>
						</MapContainer>
					</Col>
				</Row>
			</section>
			<AlertPop visible={notifVisible} color={notifColor} text={notifMessage} toggle={handleNotifToggle} />
		</Fragment>
	);
};

export default Map;
