import React, {useState} from 'react';
import FormErrors from "./Utility_Login/FormErrors";
import Validate from "./Utility_Login/FormValidation";
import { Auth } from 'aws-amplify';
import './Login.css'

// reactstrap components
import {
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Container
} from 'reactstrap';

const Login = (props) => {

	// const [username, setUsername ] = useState("");
	// const [password, setPassword ] = useState("");
	const [u, setU ] = useState({
		username: '',
		password: ''
	})
	const [errors, setErrors ] = useState({})

	const clearErrorState = () => {
		setErrors({
			cognito: null,
			blankfield: false
		});
	};

	const handleSubmit = async event => {
		event.preventDefault();
		console.log(errors)

		//form validation
		clearErrorState();
		const error = Validate(event, errors);
		if (error) {
			setErrors({...errors, ...error})
		}

		//AWS Cognito Integration here
		try{
			const user = await Auth.signIn(
				u.username, u.password
			);
			props.auth.setAuthStatus(true);
			props.auth.setUser(user);
			props.history.push("/admin/index");
		} catch(error) {
			let err = null;
			!error.message ? err = { "message": error } : err = error;
			setErrors({
				...errors, 
				cognito: err
			})
		}
	}

	const onInputChange = (event) => {
		const value = event.target.value
		setU({
			...u,
			[event.target.id]:value,	
	});
	document.getElementById(event.target.id).classList.remove("is-danger")
	}

	
	return (
		<section className='py-5 bg-login'>
			<Container>
				<Row className='justify-content-center'>
					<Col lg='5' md='7'>
						<Card className='bg-secondary shadow border-0'>
							<CardBody className='px-lg-5 py-lg-5'>
								<div className='text-center text-muted mb-4'>
									<small>Sign in with your credentials</small>
								</div>

								<div className="text-danger">
								<FormErrors formerrors={errors} />
								</div>
								<Form role='form' onSubmit={handleSubmit}>
									<FormGroup className='mb-3'>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-email-83' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder='Username'
												type='text'
												autoComplete='new-email'
												id="username"
												aria-describedby="usernameHelp"
												placeholder="username or email"
												value={u.username}
												onChange={onInputChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup className='input-group-alternative'>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='ni ni-lock-circle-open' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder='Password'
												type='password'
												autoComplete='new-password'
												id="password"
												aria-describedby="passwordHelp"
												placeholder="password"
												value={u.password}
												onChange={onInputChange}
											/>
										</InputGroup>
										<div className='custom-control custom-control-alternative custom-checkbox'>
										<input className='custom-control-input' id=' customCheckLogin' type='checkbox' />
										<label className='custom-control-label' htmlFor=' customCheckLogin'>
											<span className='text-muted'>Remember me</span>
										</label>
										</div>
										
									</FormGroup>
									<div className="field">
									<p className="control">
										<button 
										type="submit"
										className="btn btn-primary btn-block">
										Login
										</button>
									</p>
										</div>
								</Form>
							</CardBody>
						</Card>
						<Row className='mt-3'>
							<Col>
								<a className='text-light float-right' href='#pablo' onClick={(e) => e.preventDefault()}>
									<small>Forgot password?</small>
								</a>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
			<div className='separator separator-bottom separator-skew zindex-100'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					preserveAspectRatio='none'
					version='1.1'
					viewBox='0 0 2560 100'
					x='0'
					y='0'>
					<polygon className='fill-primary' points='2560 0 2560 100 0 100' />
				</svg>
			</div>
		</section>
	);
};

export default Login;
