import React from 'react';
import { Bar } from 'react-chartjs-2';

//aws AppSync
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';

const sensor = gql`
	query MyQuery {
		queryMountainDews2SBySitebyDate(Site: "1R", first: 10) {
			items {
				BatteryLevel
				Date
				Humidity
				Integrity
				KeyId
				Pressure
				RainRate
				Site
				Temperature
				WindSpeed
			}
		}
	}
`;

const temperatureChart = (props) => {
	var obj = props.todos;

	var date = Object.entries(obj).map(([k, v], i) => {
		return [obj[k].Date];
	});

	var rainRate = Object.entries(obj).map(([k, v], i) => {
		return obj[k].Temperature;
	});

	const data1 = {
		labels: date,
		datasets: [
			{
				label: 'Humidity',
				data: rainRate,
				fill: false,
				backgroundColor: 'rgb(255, 99, 132)',
				borderColor: 'rgba(255, 99, 132, 0.2)'
			}
		]
	};

	const options = {
		scales: {
			yAxes: [
				{
					ticks: {
						callback: function (value) {
							if (!(value % 10)) {
								//return '$' + value + 'k'
								return value;
							}
						}
					}
				}
			]
		},
		tooltips: {
			callbacks: {
				label: function (item, data) {
					var label = data.datasets[item.datasetIndex].label || '';
					var yLabel = item.yLabel;
					var content = '';
					if (data.datasets.length > 1) {
						content += label;
					}
					content += yLabel;
					return content;
				}
			}
		}
	};

	return (
		<div>
			<Bar data={data1} options={options} />
		</div>
	);
};

export default compose(
	graphql(sensor, {
		options: {
			fetchPolicy: 'cache-and-network'
		},
		props: (props) => ({
			todos: props.data.queryMountainDews2SBySitebyDate
				? props.data.queryMountainDews2SBySitebyDate.items
				: []
		})
	})
)(temperatureChart);
